import catagory_types from "./catagory.types";

const initialState = {
  catagories: [],
};

const catagoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case catagory_types.SET_CATAGORIES:
      return {
        ...state,
        catagories: action.payload,
      };
    case catagory_types.GET_CATAGORIES:
      return {
        ...state,
        catagories: action.payload,
      };
    default:
      return state;
  }
};

export default catagoryReducer;
