import React, { Component } from "react";

import { Carousel } from "react-responsive-carousel";

export class carousel extends Component {
  render() {
    return (
        <Carousel autoPlay infiniteLoop showThumbs={false}>
          <img src={require("./template (1).png")} alt="template 1" />
          <div className="">
            <img src={require("./template (4).png")} alt="template 2" />
          </div>
          <div className="">
            <img src={require("./template (2).png")} alt="template 3" />
          </div>
        </Carousel>
    );
  }
}

export default carousel;
