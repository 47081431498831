import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from "./Menu";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./Login";
import { auth, handelUserProfile } from "./Firebase/utils";
import Register from "./Register";
import Recovery from "./Recovery";
import { setCurrentUser } from "./Redux/User/user.actions.js";
import {
  fetchProductsStart,
  setProducts,
} from "./Redux/Products/products.actions";
import { connect } from "react-redux";
import { isAdmin } from "./common/isAdmin";
import Admin from "./Admin";
import Home from "./Home";
import Category from './Category'
import Product from './Product'
import Cart from './Cart'
import 'leaflet/dist/leaflet.css';
import AddAddress from './Cart/addAdress' 
import MyOrders from './MyOrders'

class App extends Component {
  state = {
    currentUser: null,
  };
  // currentUser = this.props.currentUser;

  authListener = null;

  componentDidMount() {
    this.authListener = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await handelUserProfile(userAuth, {});
        userRef.onSnapshot((snapshot) => {
          this.props.setCurrentUser({
            id: snapshot.id,
            ...snapshot.data(),
          });
        });
      }
      this.props.setCurrentUser(null);
    });
  }

  componentWillUnmount() {
    this.authListener();
  }

  render() {
    return (
      <>
        <Router>
          <Header />
          <Switch>
            <Route
              exact
              path="/Admin"
              render={() => {
                if (isAdmin(this.props.currentUser)) {
                  return <Admin />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />
            <Route
              exact
              path="/product/:id"
              render={(props) => {
                return <Product id={props.match.params.id} />;
              }}
            />
            <Route
              exact
              path="/cart"
              render={() => {
                return <Cart />;
              }}
            />
            <Route
              exact
              path="/orders"
              render={() => {
                return <MyOrders />;
              }}
            />
            <Route
              exact
              path="/address"
              render={() => {
                return <AddAddress />;
              }}
            />
            <Route
              exact
              path="/category/:cat_name"
              render={(props) => {
                return <Category catName={props.match.params.cat_name} />;
              }}
            />
            <Route
              exact
              path="/Login"
              render={() => {
                if (this.props.currentUser === null) {
                  return <Login />;
                } else {
                  return <Redirect to="/" />;
                }
              }}
            />
            <Route
              exact
              path="/Register"
              render={() => {
                if (this.props.currentUser === null) {
                  return <Register />;
                } else {
                  return <Redirect to="/" />;
                }
              }}
            />
            <Route
              exact
              path="/recovery"
              render={() => {
                if (this.props.currentUser) {
                  return <Recovery />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />
            <Route
              exact
              path="/"
              render={() => {
                if (this.props.currentUser) {
                  return <Home />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />
          </Switch>
        </Router>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispacth) => ({
  setCurrentUser: (user) => dispacth(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
