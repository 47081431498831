import React, { Component } from "react";
import { Card, Image } from "semantic-ui-react";
import {withRouter} from "react-router-dom"

export class products extends Component {
    render() {
      
    return (
      <Card
        raised
        link
        onClick={()=> this.props.history.push(`/product/${this.props.item.id}`)}
        style={{
          width: "15rem",
          height: "22rem",
          display: "inline-block",
          marginRight: "2rem",
        }}
      >
        <Image src={this.props.item.imageURL} />
        <Card.Content>
          <Card.Header>{this.props.item.name}</Card.Header>
          <Card.Meta>{this.props.item.catagory}</Card.Meta>
          <Card.Description>{`${this.props.item.price} ETB`}</Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

export default withRouter(products);
