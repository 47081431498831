import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Table,
  Divider,
  Segment,
  Header,
  List,
  Button,
  Modal,
  Icon,
  Message,
} from "semantic-ui-react";
import CartProducts from "./product";
import { updateCart, emptyCart } from "../Redux/Cart/cart.actions";
import AddAdress from "./addAdress";
import { firestore } from "../Firebase/utils";
import AddPhone from "./addPhone";

const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export class Cart extends Component {
  static propTypes = {
    cart: PropTypes.array.isRequired,
  };

  state = {
    emptyModalShow: false,
    addressMapModal: false,
    shippingAddress: {
      gotAddy: false,
      locName: "",
      latlng: [],
    },
    phoneAdded: false,
    orderAdded: false,
    loading: false,
    err: false,
  };

  componentWillMount() {
    if (this.props.currentUser.phoneNumber) {
      this.setState({ phoneAdded: true });
    }
  }

  setAddy = (shippingAddy) => {
    this.setState({ shippingAddress: shippingAddy, addressMapModal: false });
  };
  placeOrder = () => {
    this.setState({ loading: true, err: false });
    let data = {
      address: {
        latlng: this.state.shippingAddress.latlng,
        locName: this.state.shippingAddress.locName,
      },
      products: this.props.cart,
      orderedBy: {
        userId: this.props.currentUser.id,
        userInfo: {
          name: this.props.currentUser.displayName,
          phoneNumber: this.props.currentUser.phoneNumber,
        },
      },
      deliverdBy: {
        driverId: "",
        driverInfo: {
          name: "",
          phoneNumber: "",
        },
      },
      status: "pending",
      orderDate: new Date()
    };
    firestore
      .collection("orders")
      .doc()
      .set(data)
      .then(() => {
        this.setState({ orderAdded: true });
        this.props.emptyCart();
        this.setState({loading: false})
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, err: true });
      });
  };
  handelQuantityChange = (type, i, e) => {
    if (type === "plus" && !(e.target.value >= this.props.cart.quantity)) {
      this.props.cart[i].quantity += 1;
      this.props.updateCart(this.props.cart);
    } else if (type === "minus" && !(e.target.value <= 0)) {
      this.props.cart[i].quantity -= 1;
      this.props.updateCart(this.props.cart);
    }
    this.forceUpdate();
  };
  handelRemove = (i) => {
    let newCart = [...this.props.cart];
    newCart.splice(i, 1);
    this.props.updateCart(newCart);
  };
  emptyMyCart = () => {
    this.setState({ emptyModalShow: false });
    this.props.emptyCart();
  };
  update = () => {
    this.setState({ phoneAdded: true });
  };
  render() {
    if (this.state.orderAdded) {
      return (
        <Segment
          placeholder
          style={{ height: "90%", position: "absolute", width: "100%" }}
        >
          <Header icon>
            <Icon color="green" name="check circle" />
            Your order was added!
            <Header.Subheader>
              To track your order goto <b>My Account</b> then <b>Orders</b>
            </Header.Subheader>
          </Header>
          <Button
            icon="left arrow"
            content="Back To Home"
            onClick={() => this.props.history.push("/")}
            color="black"
          />
        </Segment>
      );
    } else if (this.props.cart.length == 0) {
      return (
        <Segment
          placeholder
          style={{ height: "90%", position: "absolute", width: "100%" }}
        >
          <Header icon>
            <Icon name="inbox" />
            Your cart is empty
          </Header>
          <Button
            icon="left arrow"
            content="Continue Shopping"
            onClick={() => this.props.history.push("/")}
            color="black"
          />
        </Segment>
      );
    } else {
      let total = 0;
      this.props.cart.map(
        (item) => (total = parseInt(item.price) * item.quantity + total)
      );
      return (
        <>
          <Modal basic open={this.state.emptyModalShow}>
            <Header icon>
              <Icon color="red" name="trash alternate" />
              {`Are you sure you want to empty your cart?`}
            </Header>
            <Modal.Actions>
              <Button
                basic
                color="green"
                inverted
                onClick={() => {
                  this.setState({ emptyModalShow: false });
                }}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="red" inverted onClick={this.emptyMyCart}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal size="large" open={this.state.addressMapModal}>
            <Modal.Header>Add a Shipping Address</Modal.Header>
            <Modal.Content>
              <AddAdress setAddy={this.setAddy} />
            </Modal.Content>
            <Modal.Actions>
              <Button
                negative
                onClick={() => this.setState({ addressMapModal: false })}
              >
                <Icon name="times" />
                Close
              </Button>
            </Modal.Actions>
          </Modal>
          <Segment basic>
            <Grid columns="2" stackable style={{ margin: "2rem" }}>
              <Grid.Column width="9">
                <Table stackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell width="5">Quantity</Table.HeaderCell>
                      <Table.HeaderCell>Sub Total</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.cart.map((item, itetarion) => (
                      <CartProducts
                        key={itetarion}
                        handelRemove={this.handelRemove}
                        formatMoney={formatMoney}
                        handelQuantityChange={this.handelQuantityChange}
                        item={item}
                        index={itetarion}
                      />
                    ))}
                  </Table.Body>
                  <Table.Footer fullWidth>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Header as="h4">Total</Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                      <Table.HeaderCell>
                        <Header as="h4">{`${formatMoney(total)} ETB`}</Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        <Button
                          icon="trash"
                          content="Empty Cart"
                          color="red"
                          onClick={() =>
                            this.setState({ emptyModalShow: true })
                          }
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Grid.Column>
              <Grid.Column width="7">
                <Header as="h3" dividing>
                  Cart Totals
                </Header>
                <List divided relaxed size="large">
                  <List.Item>
                    <List.Content floated="right">
                      <Header as="h4">{`${formatMoney(total)} ETB`}</Header>
                    </List.Content>
                    <List.Content>Subtotal</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>Shipping</List.Content>
                    <List.Content floated="right">
                      {this.state.shippingAddress.gotAddy ? (
                        <p style={{ float: "right", textAlign: "right" }}>
                          {this.state.shippingAddress.locName}
                          <br />
                          <a
                            onClick={() =>
                              this.setState({ addressMapModal: true })
                            }
                          >
                            Change Shipping Adress
                          </a>
                        </p>
                      ) : (
                        <p style={{ float: "right", textAlign: "right" }}>
                          No address added
                          <br />
                          <a
                            onClick={() =>
                              this.setState({ addressMapModal: true })
                            }
                          >
                            Click here to add a Shipping Adress
                          </a>
                        </p>
                      )}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content floated="right">
                      <Header as="h4">{`${formatMoney(total)} ETB`}</Header>
                    </List.Content>
                    <List.Content>Total</List.Content>
                  </List.Item>
                </List>
                {!this.state.loading? <Button
                  disabled={
                    !this.state.shippingAddress.gotAddy
                      ? this.state.phoneAdded
                        ? !this.state.shippingAddress.gotAddy
                        : true
                      : this.state.phoneAdded
                      ? !this.state.shippingAddress.gotAddy
                      : true
                  }
                  color="black"
                  onClick={this.placeOrder}
                  content={
                    this.state.shippingAddress.gotAddy
                      ? this.state.phoneAdded
                        ? "Place order"
                        : "Add a phone number first"
                      : "Add an adress first"
                  }
                  style={{ marginTop: "2rem" }}
                  fluid
                />: <Button color="black" style={{ marginTop: "2rem" }} fluid disabled={true}><Icon loading name="notched circle"></Icon>Placing order</Button>}
                {this.state.err && <Message negative fluid header="Something went wrong" content="Your order did not complete. Please try again"/>}
                {!this.state.phoneAdded && <AddPhone forUpdate={this.update} />}
              </Grid.Column>
            </Grid>
          </Segment>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  cart: state.cart.cart,
  currentUser: state.user.currentUser,
});
export default connect(mapStateToProps, { updateCart, emptyCart })(
  withRouter(Cart)
);
