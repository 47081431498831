import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import AdminStaff from './adminStaff'
import DriverStaff from './driverStaff'
export class staff extends Component {
    render() {
        return (
            <Grid columns="equal">
                <Grid.Column>
                    <AdminStaff />
                </Grid.Column>
                <Grid.Column>
                    <DriverStaff />
                </Grid.Column>
            </Grid>
        )
    }
}

export default staff
