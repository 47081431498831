import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Image,
  Button,
  Icon,
  Input,
  Label,
  GridRow,
  Item
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../Redux/Cart/cart.actions";

export class oneProduct extends Component {
  state = {
    quantity: 1,
    product: this.props.item,
    addedToCart: false,
  };
  componentDidMount() {
    console.log(this.props.item)
    this.setState({
      quantity: 1,
      product: this.props.item,
      addedToCart: false,
    });
  }
  formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  };
  handelAddToCart = () => {
    this.props.addToCart({
      prodId: this.state.product.id,
      name: this.state.product.name,
      quantity: this.state.quantity,
      category: this.state.product.catagory,
      price: this.state.product.price,
      orderDate: new Date(),
      imageURL: this.state.product.imageURL,
    });
    this.setState({ addedToCart: true });
  };
  handelValueChange = (e) => {
    if (
      !(e.target.value > this.state.product.quantity) &&
      !(e.target.value.length == 0)
    ) {
      this.setState({ quantity: e.target.value });
    } else if (e.target.value.length == 0) {
      this.setState({ quantity: 0 });
    }
  };

  handelQuantityChange = (type) => {
    if (
      type === "plus" &&
      !(this.state.quantity >= this.state.product.quantity)
    ) {
      console.log(type);
      this.setState((prevState) => {
        return { quantity: prevState.quantity + 1 };
      });
    } else if (type === "minus" && !(this.state.quantity <= 0)) {
      console.log(type);
      this.setState((prevState) => {
        return { quantity: prevState.quantity - 1 };
      });
    } else {
      console.log("here");
    }
  };
  render() {
    return (
      <Segment
        inverted
        style={{ borderRadius: "0", marginTop: "0", marginBottom: "0" }}
      >
        <Grid stackable>
          <Grid.Row stretched columns="2">
            <Grid.Column width="6">
              <Image rounded size="huge" src={this.props.item.imageURL} />
            </Grid.Column>
            <Grid.Column width="10">
              <Header inverted as="h1">
                {this.props.item.name}
                <Header.Subheader>{this.props.item.catagory}</Header.Subheader>
              </Header>
              <Header style={{ marginTop: "2rem" }} inverted as="h3">
                {this.props.item.description}
              </Header>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width="6" verticalAlign="middle">
                  <Input
                    labelPosition="right"
                    style={{ width: "5rem" }}
                    value="0"
                    action
                    type="text"
                  >
                    <Label
                      color="teal"
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: ".2rem",
                      }}
                      onClick={() => this.handelQuantityChange("minus")}
                      as="a"
                    >
                      <Icon name="minus" />
                    </Label>
                    <input
                      onChange={this.handelValueChange}
                      value={this.state.quantity}
                      style={{ textAlign: "center" }}
                    />
                    <Label
                      color="teal"
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: ".2rem",
                      }}
                      onClick={() => this.handelQuantityChange("plus")}
                      as="a"
                    >
                      <Icon name="plus" />
                    </Label>
                  </Input>
                  {this.state.addedToCart ? (
                    <Button
                      style={{
                        marginLeft: "6rem",
                        display: "inline",
                        width: "50%",
                      }}
                      fluid
                      width="6"
                      disabled
                      icon="check circle"
                      content="Added To Cart"
                    />
                  ) : (
                    <Button
                      style={{
                        marginLeft: "6rem",
                        display: "inline",
                        width: "50%",
                      }}
                      fluid
                      animated="fade"
                      onClick={this.handelAddToCart}
                    >
                      <Button.Content visible>Add to Cart</Button.Content>
                      <Button.Content hidden>
                        {`Total ${this.formatMoney(
                          this.state.quantity * this.state.product.price
                        )} ETB`}
                      </Button.Content>
                    </Button>
                  )}
                </Grid.Column>
                {/* <Grid.Column width="6" verticalAlign="middle"></Grid.Column> */}
              </Grid.Row>
              <br />
              <GridRow>
                <Button
                  onClick={() =>
                    this.props.history.push(`product/${this.props.item.id}`)
                  }
                  labelPosition="right"
                  fluid
                  color="teal"
                  icon="chevron right"
                  content="More Details On This Product"
                />
              </GridRow>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default connect(null, { addToCart })(withRouter(oneProduct));
