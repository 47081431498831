import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Dropdown, Icon, Search, Responsive } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../Firebase/utils";
import { isAdmin } from "../common/isAdmin";
import { withRouter } from "react-router";
import './menu.css'
import SerachComp from './search'

export class Header extends Component {
  unregisterd = () => {
    return (
      <>
        <a className="item" name="home" href="/login">
          Login
        </a>
        <a className="item" name="home" href="/register">
          Register
        </a>
      </>
    );
  };
  registerd = () => {
    return (
      <>
        <a className="item" onClick={() => this.props.history.push("/cart")}>
          <div className="">
            <i className="shop icon"></i>
            {this.props.cart.length >= 1 ? (
              <div class="left pointing mini ui teal label">
                {this.props.cart.length}
              </div>
            ) : null}
          </div>
        </a>
        <Dropdown text={this.props.currentUser.displayName} item>
          <Dropdown.Menu>
            <Dropdown.Item
              icon="box"
              text="My Orders"
              onClick={() => {
                this.props.history.push("/orders");
              }}
            />
            {isAdmin(this.props.currentUser) && (
              <Dropdown.Item
                icon="circle user"
                text="Admin"
                onClick={() => {
                  this.props.history.push("/admin");
                }}
              />
            )}
            <Dropdown.Item
              icon="log out"
              text="Log Out"
              onClick={() => {
                auth.signOut();
              }}
            />
            <Dropdown.Item
              icon="info"
              text="About Us"
              onClick={() => {
                console.log("clicked");
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  render() {
    return (
      <div
        className="ui borderless inverted icon menu"
        style={{ borderRadius: "0", width: "100%", margin: "0" }}
      >
        <a
          className="item"
          onClick={() => {
            this.props.history.push("/");
          }}
        >
          Home
        </a>
        <SerachComp />
        <div className="right menu">
          {this.props.currentUser ? this.registerd() : this.unregisterd()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  cart: state.cart.cart
});
export default connect(mapStateToProps, null)(withRouter(Header));
