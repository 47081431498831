import React, { Component } from "react";
import {
  Table,
  Header,
  Image,
  Input,
  Label,
  Icon,
  Button,
} from "semantic-ui-react";
import {withRouter, Link} from 'react-router-dom'

export class product extends Component {
  render() {
    return (
      <>
        <Table.Row>
          <Table.Cell>
            <Link>
              <Header
                as="h4"
                onClick={() =>
                  this.props.history.push(`/product/${this.props.item.prodId}`)
                }
                image
              >
                <Image src={this.props.item.imageURL} rounded size="mini" />
                <Header.Content>
                  {this.props.item.name}
                  <Header.Subheader>
                    {this.props.item.category}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Link>
          </Table.Cell>
          <Table.Cell>
            <Input
              labelPosition="right"
              style={{ width: "5rem" }}
              value="0"
              action
              type="text"
            >
              <Label
                color="grey"
                style={{ paddingLeft: "1rem", paddingRight: ".2rem" }}
                onClick={(e) =>
                  this.props.handelQuantityChange("minus", this.props.index, e)
                }
                as="a"
              >
                <Icon name="minus" />
              </Label>
              <input
                onChange={this.props.handelValueChange}
                value={this.props.item.quantity}
                style={{ textAlign: "center" }}
              />
              <Label
                color="grey"
                style={{ paddingLeft: "1rem", paddingRight: ".2rem" }}
                onClick={(e) =>
                  this.props.handelQuantityChange("plus", this.props.index, e)
                }
                as="a"
              >
                <Icon name="plus" />
              </Label>
            </Input>
          </Table.Cell>
          <Table.Cell>
            <Header size="small">
              {`${this.props.formatMoney(
                this.props.item.quantity * this.props.item.price
              )} ETB`}
              <Header.Subheader>{`${this.props.item.quantity} x ${this.props.item.price} ETB`}</Header.Subheader>
            </Header>
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Button
              basic
              icon="remove"
              content="Remove"
              color="red"
              onClick={() => this.props.handelRemove(this.props.index)}
            />
          </Table.Cell>
        </Table.Row>
      </>
    );
  }
}

export default withRouter(product);
