import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCatagories } from "../../../Redux/Catagory/catagory.actions";
import { Header, Segment, List } from "semantic-ui-react";
import CatList from "./catList";

let catList;
export class ViewCat extends Component {
  state = {
    loading: false,
  };
  componentDidMount() {
    this.setState({ loading: true });
      setTimeout(() => {
          console.log(this.props.catagories)
      catList = this.props.catagories.map((item) => <CatList item={item} />);
      this.setState({ loading: false });
    }, 2000);
  }
  static propTypes = {
    getCatagories: PropTypes.func.isRequired,
  };

    catList = this.props.catagories.map((item, iteration) => <CatList key={iteration} item={item} />);

  render() {
    return (
      <>
        <Header as="h2" inverted attached="top">
          Add a new catagory
        </Header>
        <Segment attached loading={this.state.loading}>
          <List divided relaxed>
            {this.state.loading ? (
              <>
                <br /> <br /> <br /> <br />
              </>
            ) : (
              catList
            )}
          </List>
        </Segment>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  catagories: state.catagories.catagories,
});

export default connect(mapStateToProps, { getCatagories })(ViewCat);
