import { combineReducers } from 'redux';
import userReducer from './User/user.reducer'
import productsReducer from './Products/products.reducer'
import catagoryReducer from './Catagory/catagory.reducer'
import cartReducer from './Cart/cart.reducer'

export default combineReducers({
    user: userReducer,
    products: productsReducer,
    catagories: catagoryReducer,
    cart: cartReducer
})