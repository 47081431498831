import React, { Component } from "react";
import { createMedia } from "@artsy/fresnel";
import {
  Segment,
  Header,
  Icon,
  Form,
  Grid,
  Button,
  Popup,
  Message,
} from "semantic-ui-react";
import { auth } from "../Firebase/utils";
import { withRouter } from "react-router-dom";

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

export class Recovery extends Component {
  state = {
    email: "",
    loading: false,
    err: { err: false, msg: "" },
    success: false,
  };
  
  handelSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, err: { err: false, msg: "" } });
    const { email } = this.state;
    const config = { url: "http://localhost:3001/login" };
    try {
      await auth.sendPasswordResetEmail(email, config).then( (res) => {
        console.log("here", res);
          this.setState({ success: true, err: { err: false, msg: "" } });          
      });
    } catch (err) {
      if (err.code == "auth/user-not-found") {
        this.setState({
          err: {
            err: true,
            msg: "There is no registered user with that email address.",
          },
        });
      } else if (err.code == "auth/invalid-email") {
        this.setState({
          err: {
            err: true,
            msg: "Please enter a vaild email",
          },
        });
      }
      else if (err.code == "auth/too-many-requests") {
          this.setState({
            err: {
              err: true,
              msg: err.message,
            },
          });
      }
      else {
        console.log(err);
        this.setState({
          err: {
            err: true,
            msg: "Oops... Something went wrong. Please try again.",
          },
        });
      }
    }

    this.setState({ loading: false });
  };
  handelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className="">
        <br />
        <Grid centered>
          <MediaContextProvider>
            <Grid.Column width={"12"} as={Media} lessThan="computer">
              <Header as="h2" attached="top">
                <Icon name="history" />
                <Header.Content>
                  Recover your account
                  <Header.Subheader>
                    Enter your email to recover your account
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Segment attached loading={this.state.loading} raised>
                <Form onSubmit={this.handelSubmit}>
                  <Form.Field>
                    <label>
                      Email
                      <Popup
                        trigger={
                          <Icon
                            color="grey"
                            style={{ float: "right" }}
                            name="question circle"
                          />
                        }
                        content="We will send you an email to recover your account. In that email there will be a link available for recovering your account."
                        inverted
                        position="bottom left"
                        header="How dose this work?"
                      />
                    </label>

                    <input
                      placeholder="Email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handelChange}
                      name="email"
                    />
                  </Form.Field>
                  <Button
                    icon
                    type="submit"
                    labelPosition="right"
                    fluid
                    color="black"
                  >
                    Recover
                    <Icon name="sync" />
                  </Button>
                </Form>
                <Message
                  icon="green check circle"
                  compact={false}
                  hidden={!this.state.success}
                  positive
                  header="Success"
                  content={`An email conating a link has been sent to ${this.state.email}, follow that link to reset your password.`}
                />
                <Message negative compact hidden={!this.state.err.err}>
                  {this.state.err.msg}
                </Message>
              </Segment>
            </Grid.Column>
            <Grid.Column width={"8"} as={Media} greaterThanOrEqual="computer">
              <Header as="h2" attached="top">
                <Icon name="history" />
                <Header.Content>
                  Recover your account
                  <Header.Subheader>
                    Enter your email to recover your account
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Segment attached loading={this.state.loading} raised>
                <Form onSubmit={this.handelSubmit}>
                  <Form.Field>
                    <label>
                      Email
                      <Popup
                        trigger={
                          <Icon
                            color="grey"
                            style={{ float: "right" }}
                            name="question circle"
                          />
                        }
                        content="We will send you an email to recover your account. In that email there will be a link available for recovering your account."
                        inverted
                        position="bottom left"
                        header="How dose this work?"
                      />
                    </label>

                    <input
                      placeholder="Email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handelChange}
                      name="email"
                    />
                  </Form.Field>
                  <Button
                    icon
                    type="submit"
                    labelPosition="right"
                    fluid
                    color="black"
                    disabled={this.state.success}
                  >
                    Recover
                    <Icon name="sync" />
                  </Button>
                </Form>
                <Message
                  icon="green check circle"
                  compact={false}
                  hidden={!this.state.success}
                  positive
                  header="Success"
                  content={`An email conating a link has been sent to ${this.state.email}, follow that link to reset your password.`}
                />
                {/* <Message negative compact={false} hidden={!this.state.err.err}>
                  {this.state.err.msg}
                </Message> */}
              </Segment>
            </Grid.Column>
          </MediaContextProvider>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Recovery);
