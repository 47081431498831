import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Image,
  Header,
  Divider,
  Input,
  Label,
  Icon,
  Button,
  Tab,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { addToCart } from "../Redux/Cart/cart.actions";

export class Product extends Component {
  state = {
    quantity: 1,
    addedToCart: false,
    product: {
      id: "",
      name: "",
      price: "",
      quantity: 10,
      description: "",
      catagory: "",
      imageURL: "",
    },
  };
  static propTypes = {
    Products: PropTypes.array.isRequired,
  };
  componentWillMount() {
    this.setState({
      product: this.props.Products.find((o) => o.id === this.props.id),
    });
  }
  componentDidMount() {
    if (localStorage.getItem("recent")) {
      let recent = JSON.parse(localStorage.getItem("recent"));
      if (recent.length >= 10) {
        recent.shift();
        if (!(recent.find((o) => o.id === this.state.product.id))) {
          recent.push({
            name: this.state.product.name,
            id: this.state.product.id,
            catagory: this.state.product.catagory,
            price: this.state.product.price,
            imageURL: this.state.product.imageURL
          });
        }
      } else {
        if (!(recent.find((o) => o.id === this.state.product.id))) {
          recent.push({
            name: this.state.product.name,
            id: this.state.product.id,
            catagory: this.state.product.catagory,
            price: this.state.product.price,
            imageURL: this.state.product.imageURL,
          });
        }
      }
      localStorage.setItem("recent", JSON.stringify(recent));
    } else {
      let firstData = [
        {
          name: this.state.product.name,
          id: this.state.product.id,
          catagory: this.state.product.catagory,
          price: this.state.product.price,
          imageURL: this.state.product.imageURL,
        },
      ];
      localStorage.setItem("recent", JSON.stringify(firstData));
    }
  }
  handelQuantityChange = (type) => {
    if (
      type === "plus" &&
      !(this.state.quantity >= this.state.product.quantity)
    ) {
      this.setState((prevState) => {
        return { quantity: prevState.quantity + 1 };
      });
    } else if (type === "minus" && !(this.state.quantity <= 0)) {
      this.setState((prevState) => {
        return { quantity: prevState.quantity - 1 };
      });
    }
  };
  handelValueChange = (e) => {
    if (
      !(e.target.value > this.state.product.quantity) &&
      !(e.target.value.length == 0)
    ) {
      this.setState({ quantity: e.target.value });
    } else if (e.target.value.length == 0) {
      this.setState({ quantity: 0 });
    }
  };
  formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  };
  handelAddToCart = () => {
    this.props.addToCart({
      prodId: this.state.product.id,
      name: this.state.product.name,
      quantity: this.state.quantity,
      category: this.state.product.catagory,
      price: this.state.product.price,
      orderDate: new Date(),
      imageURL: this.state.product.imageURL,
    });
    this.setState({ addedToCart: true });
  };
  render() {
    return (
      <Grid stackable>
        <Grid.Row style={{ margin: "2rem" }}>
          <Grid.Column width="8">
            <Image
              rounded
              bordered
              size="big"
              src={this.state.product.imageURL}
              fluid
            />
          </Grid.Column>
          <Grid.Column width="8">
            <Header as="h2">
              <Header.Subheader>{this.state.product.catagory}</Header.Subheader>
              {this.state.product.name}
            </Header>
            <Divider />
            <Header as="h3" color="green">
              <Header.Subheader>Price</Header.Subheader>
              {`${this.state.product.price} ETB`}
            </Header>
            {this.state.product.quantity == 0 ? (
              <Header as="h3" color="red">
                <Header.Subheader>Availbility</Header.Subheader>
                Out of Stock
              </Header>
            ) : this.state.product.quantity <= 10 ? (
              <Header as="h3" color="orange">
                <Header.Subheader>Availbility</Header.Subheader>
                {`Only ${this.state.product.quantity} Available`}
              </Header>
            ) : (
              <Header as="h3" color="green">
                <Header.Subheader>Availbility</Header.Subheader>
                In Stock
              </Header>
            )}

            <p style={{ fontSize: "18px" }}>{this.state.product.description}</p>
            <Grid>
              <Grid.Row>
                <Grid.Column width="8">
                  <Input
                    labelPosition="right"
                    style={{ width: "5rem" }}
                    value="0"
                    action
                    type="text"
                  >
                    <Label
                      color="teal"
                      style={{ paddingLeft: "1rem", paddingRight: ".2rem" }}
                      onClick={() => this.handelQuantityChange("minus")}
                      as="a"
                    >
                      <Icon name="minus" />
                    </Label>
                    <input
                      onChange={this.handelValueChange}
                      value={this.state.quantity}
                      style={{ textAlign: "center" }}
                    />
                    <Label
                      color="teal"
                      style={{ paddingLeft: "1rem", paddingRight: ".2rem" }}
                      onClick={() => this.handelQuantityChange("plus")}
                      as="a"
                    >
                      <Icon name="plus" />
                    </Label>
                  </Input>
                </Grid.Column>
                <Grid.Column width="8">
                  {this.state.addedToCart ? (
                    <Button
                      fluid
                      disabled
                      color="black"
                      icon="check circle"
                      content="Added To Cart"
                    />
                  ) : (
                    <Button
                      animated="fade"
                      fluid
                      color="black"
                      onClick={this.handelAddToCart}
                    >
                      <Button.Content visible>Add to Cart</Button.Content>
                      <Button.Content hidden>
                        {`Total ${this.formatMoney(
                          this.state.quantity * this.state.product.price
                        )} ETB`}
                      </Button.Content>
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <p>
              Category:{" "}
              <Link to={`/category/${this.state.product.catagory}`}>
                {this.state.product.catagory}
              </Link>
            </p>
            <div>
              <Button circular color="facebook" icon="facebook" />
              <Button circular color="twitter" icon="twitter" />
              <Button circular color="linkedin" icon="linkedin" />
              <Button circular color="google plus" icon="google plus" />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{
            marginLeft: "3rem",
            marginRight: "3rem",
            marginBottom: "1rem",
          }}
        >
          <Tab
            panes={[
              {
                menuItem: "Descirption",
                pane: this.state.product.description,
              },
            ]}
            renderActiveOnly={false}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  Products: state.products.products,
});

export default connect(mapStateToProps, { addToCart })(withRouter(Product));
