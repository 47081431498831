import React, { Component } from "react";
import {
  Modal,
  Button,
  Image,
  Segment,
  Form,
  Input,
  Dropdown,
  TextArea,
  Icon,
} from "semantic-ui-react";
import { firestore, storage } from "../../../Firebase/utils";
import { v4 as uuid } from "uuid";

const Options = [
  { key: "1", text: "Catagory 1", value: "cat 1" },
  { key: "2", text: "Catagory 2", value: "cat 2" },
  { key: "3", text: "Catagory 3", value: "cat 3" },
];

export class EditModal extends Component {
  state = {
    name: this.props.item.name,
    catagory: this.props.item.catagory,
    price: this.props.item.price,
    quantity: this.props.item.quantity,
    description: this.props.item.description,
    image: null,
    previewUrl: this.props.item.imageURL,
    error: false,
    success: false,
    loading: false,
  };
  handelImageChange = (e) => {
    this.setState({
      image: e.target.files[0],
      previewUrl: URL.createObjectURL(e.target.files[0]),
    });
    console.log("image: ", e.target.files[0]);
  };
  handleSearchChange = (e, { value }) => {
    this.setState({ catagory: value });
    console.log(value);
  };

  handelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };
  handelSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true, error: false, success: false });

    if (this.state.image) {
      const uploadTask = storage
        .ref(`images/${this.props.item.imageUUId}`)
        .put(this.state.image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
          this.setState({ success: false, loading: false, error: true });
          return;
        },
        () => {
          storage
            .ref("images")
            .child(this.props.item.imageUUId)
            .getDownloadURL()
            .then((url) => {
              const data = {
                name: this.state.name,
                catagory: this.state.catagory,
                price: this.state.price,
                quantity: this.state.quantity,
                description: this.state.description,
                imageURL: url,
              };

              firestore
                .collection("products")
                .doc(this.props.item.id)
                .update(data)
                .then(() => {
                  this.setState({
                    success: true,
                    loading: false,
                    error: false,
                  });
                    console.log('success')
                  //   this.props.updateComp();
                })
                .catch((err) => {
                  console.log(err);
                  this.setState({
                    success: false,
                    loading: false,
                    error: true,
                  });
                });
            });
        }
      );
    } else {
      const data = {
        name: this.state.name,
        catagory: this.state.catagory,
        price: this.state.price,
        quantity: this.state.quantity,
        description: this.state.description,
        imageURL: this.state.previewUrl,
      };

      firestore
        .collection("products")
        .doc(this.props.item.id)
        .update(data)
        .then(() => {
          this.setState({
            success: true,
            loading: false,
            error: false,
          });
          console.log('success')
          //   this.props.updateComp();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            success: false,
            loading: false,
            error: true,
          });
        });
    }
  };
  render() {
    return (
      <Modal open={this.props.showModal}>
        <Modal.Header>{`Edit ${this.props.item.name}'s properties`}</Modal.Header>
        <Modal.Content image>
          <div>
            <Image size="medium" src={this.state.previewUrl} wrapped />
            <br />
            <label
              className="ui left labeled blue fluid icon button"
              style={{ marginTop: "1rem" }}
            >
              <i className="image icon"></i>
              <input
                type="file"
                style={{ display: "none", marginTop: "1rem" }}
                className="ui blue button"
                accept="image/*"
                onChange={this.handelImageChange}
              ></input>
              Update Product Image
            </label>
          </div>
          <Modal.Description style={{ marginLeft: "1rem" }}>
            <Form
              onSubmit={this.handelSubmit}
              id="update-product-form"
              loading={this.state.loading}
              style={{ height: "100%" }}
            >
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-name"
                  control={Input}
                  label="Product name"
                  placeholder="Product name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handelChange}
                />
                <Form.Field>
                  <label>Select Catagory</label>
                  <Dropdown
                    placeholder="Select Catagory"
                    fluid
                    search
                    name="catagory"
                    selection
                    value={this.state.catagory}
                    onChange={this.handleSearchChange}
                    options={Options}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-price"
                  control={Input}
                  type="number"
                  name="price"
                  label="Price"
                  placeholder="Price"
                  onChange={this.handelChange}
                  value={this.state.price}
                />
                <Form.Field
                  id="form-input-control-quantity"
                  control={Input}
                  label="Quantity"
                  name="quantity"
                  type="number"
                  placeholder="Quantity"
                  onChange={this.handelChange}
                  value={this.state.quantity}
                />
                <div className="field">
                  <label>Add Product Image</label>
                  <label className="ui left labeled icon button">
                    <i className="image icon"></i>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      className="ui blue button"
                      accept="image/*"
                      onChange={this.handelImageChange}
                    ></input>
                    Product Image
                  </label>
                </div>
              </Form.Group>
              <Form.Field
                id="form-textarea-control-opinion"
                control={TextArea}
                label="Description"
                placeholder="Description"
                name="description"
                onChange={this.handelChange}
                value={this.state.description}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button icon="times" onClick={() => this.props.hideModal(false)}>
            Cancel
          </Button>
          <Button icon="save" type="submit" form="update-product-form" positive>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditModal;
