import cart_types from './cart.types'

export const addToCart = (product) => (dispatch) => {
    dispatch({
        type: cart_types.ADD_TO_CART,
        payload: product
    }) 
}

export const removeFromCart = (newCart) => (dispatch) => {
    dispatch({
        type: cart_types.REMOVE_FROM_CART,
        payload: newCart
    })
}

export const emptyCart = () => (dispatch) => {
    dispatch({
        type: cart_types.EMPTY_CART
    })
}

export const updateCart = (updatedCart) => (dispatch) => {
    dispatch({
        type: cart_types.UPDATE_CART,
        payload: updatedCart
    })
}