import React, { Component } from 'react'
import Admin from './admin'

export class AdminParent extends Component {
    render() {
        return (
            <div>
                <Admin/>
            </div>
        )
    }
}

export default AdminParent
