import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Icon, Item, Segment } from "semantic-ui-react";
import Order from "./order";
import { connect } from "react-redux";
import { firestore } from "../Firebase/utils";

export class MyOrder extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };
  state = { isLoading: false, orders: [] };
  // orders = [];
  orderSubscribe;
  fetchOrders = async () => {
    // this.orderSubscribe = await
  };

  componentWillUnmount() {
    this.orderSubscribe();
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.orderSubscribe = firestore
      .collection("orders")
      .where("orderedBy.userId", "==", this.props.user.id)
      .onSnapshot((snapshot) => {
        let myOrders = [];
        snapshot.forEach((doc) => {
          myOrders.push({ ...doc.data(), id: doc.id });
        });
        this.setState({ orders: myOrders }, () => {
          console.log(this.state);
        });
        // this.setState({ isLoading: false });
      });
    }
    
    // componentDidUpdate() {
    //     if (this.state.orders.length > 0 && this.state.isLoading) {
    //         this.setState({ isLoading: false });
    //     }
    // }

  render() {
    if (this.state.isLoading) {
      return (
        <Segment
          placeholder
          style={{ height: "90%", position: "absolute", width: "100%" }}
        >
          <Header icon>
            <Icon name="notched circle" loading />
            Retriving Orders
          </Header>
        </Segment>
      );
    } else if ((this.state.orders.length = 0)) {
      return (
        <Segment
          placeholder
          style={{ height: "90%", position: "absolute", width: "100%" }}
        >
          <Header icon>
            <Icon name="inbox" />
            You haven't made any orders yet
            <Header.Subheader>
              When ever you make an order this is where you see the details and
              track them
            </Header.Subheader>
          </Header>
        </Segment>
      );
    } else {
      return (
        <Grid>
          <Grid.Column width="8">
            <Item.Group style={{ padding: "1rem" }}>
              {this.state.orders.map((item) => (
                <Order item={item} />
              ))}
              <Order />
              <Order />
              <Order />
            </Item.Group>
          </Grid.Column>
          <Grid.Column width="8">Grid 2</Grid.Column>
        </Grid>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.currentUser,
});

export default connect(mapStateToProps)(MyOrder);
