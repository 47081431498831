import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Header,
  Icon,
  Grid,
  Input,
  Divider,
  Dropdown,
  Menu,
  Card,
} from "semantic-ui-react";
import Products from "./listProducts";
import { connect } from "react-redux";

let prod;
export class category extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
  };

  state = {
    search: "",
    filter: null,
  };

  componentDidMount() {
    prod = this.props.products.map((item) => <Products item={item} />);
    this.setState(this.state);
  }

  handelChange = (e) => {
    if (this.state.filter === "all") {
      prod = this.props.products
        .filter(function (item) {
            if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
          } else return false;
        })
        .map((item) => <Products item={item} />);
    } else if (this.state.filter === "date") {
      prod = this.props.products
        .filter(function (item) {
            if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
          } else return false;
        })
        .sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        })
        .map((item) => <Products item={item} />);
    } else if (this.state.filter === "price") {
      prod = this.props.products
        .filter(function (item) {
            if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
          } else return false;
        })
        .sort((a, b) => {
          return new b.price() - a.price;
        })
        .map((item) => <Products item={item} />);
    } else {
      prod = this.props.products
        .filter(function (item) {
            if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
          } else return false;
        })
        .map((item) => <Products item={item} />);
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  handelFilter = (e) => {
    if (this.state.filter === "all") {
      prod = this.props.products
        .filter(function (item) {
            if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
          } else return false;
        })
        .map((item) => <Products item={item} />);
    } else if (this.state.filter === "date") {
      prod = this.props.products
        .filter(function (item) {
            if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
          } else return false;
        })
        .sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        })
        .map((item) => <Products item={item} />);
    } else if (this.state.filter === "price") {
      prod = this.props.products
        .filter(function (item) {
            if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
          } else return false;
        })
        .sort((a, b) => {
          return new b.price() - a.price;
        })
        .map((item) => <Products item={item} />);
    }
    this.setState({ filter: e.target.name });
  };
  render() {
    return (
      <>
        <Menu secondary stackable>
          <Menu.Item header as="h3">
            Habesha Cement
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Input
                icon="search"
                name="search"
                onChange={this.handelChange}
                value={this.state.search}
                placeholder="Search..."
              />
            </Menu.Item>
            <Menu.Item>
              <Dropdown item text="Sort by" onChange={this.handelFilter}>
                <Dropdown.Menu>
                  <Dropdown.Item name="all">All</Dropdown.Item>
                  <Dropdown.Item name="price_LH">Price</Dropdown.Item>
                  <Dropdown.Item name="date">Date</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Card.Group itemsPerRow={4} doubling stackable style={{ padding: "1rem" }}>
          {prod}
        </Card.Group>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.products.products,
});
export default connect(mapStateToProps)(category);
