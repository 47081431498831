import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { firestore } from "../../../Firebase/utils";
import AllOrders from "./allOrders";
import SelectedOrder from "./selectedOrder";

export class orders extends Component {
  state = {
    orders: [],
    loading: false,
    selectedOrder: null,
      drivers: [],
      driverOptions: []
    };
    unsubscribe1;
    unsubscribe2;
    unsubscribe3;
    componentWillUnmount() {
        this.unsubscribe1();
        this.unsubscribe2();
        this.unsubscribe3();
    }
    
  componentDidMount() {
    this.setState({ loading: true, orders: [] });
    this.unsubscribe1= firestore
      .collection("orders")
      .where("status", "==", "pending")
        .onSnapshot((snapshot) => {
          this.setState({orders: []},()=>{
        let ordersArray = snapshot.docs.map((doc) => {
            console.log(doc);
            
          return { ...doc.data(), id: doc.id };
        });
        this.setState((prevState) => {
          return { orders: [...prevState.orders, ...ordersArray] };
        });
        console.table(ordersArray);
          });
        })
      this.unsubscribe2 =firestore
      .collection("orders")
        .where("status", "==", "fulfilling")
      .onSnapshot((snapshot) => {
        let ordersArray = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        this.setState((prevState) => {
          return { orders: [...prevState.orders, ...ordersArray] };
        });
        console.table(ordersArray);
      });
      this.unsubscribe3 = firestore
      .collection("orders")
      .where("status", "==", "delivering")
          .onSnapshot((snapshot) => {
              let ordersArray = snapshot.docs.map((doc) => {
                  return { ...doc.data(), id: doc.id };
              });
              this.setState((prevState) => {
                  return { orders: [...prevState.orders, ...ordersArray] };
              });
        console.table(ordersArray);
      });
    this.setState({ loading: false });
      firestore
      .collection("users")
      .where("userRoles", "array-contains", "driver")
      .get()
      .then((snapshot) => {
        let driversArray = [];
        snapshot.forEach((doc) => {
          driversArray.push({ ...doc.data(), id: doc.id });
        });
          let options = driversArray.map((item, i) => {
              return {key: i, value: item, text: item.displayName}
          })
          console.table(options)

        this.setState({ drivers: driversArray, driverOptions: options });
      });
  }
  setSelectedOrder = (order) => {
    this.setState({ selectedOrder: order });
  };
  render() {
    if (this.state.orders.length != 0) {
      return (
        <Grid divided columns={2}>
          <Grid.Column width="10" style={{ height: "100%" }}>
            <AllOrders
              newOrders={this.state.orders}
              setSelectedOrder={this.setSelectedOrder}
            />
          </Grid.Column>
          <Grid.Column width="6">
            <SelectedOrder drivers={this.state.drivers} driverOptions={this.state.driverOptions} selectedOrder={this.state.selectedOrder} />
          </Grid.Column>
        </Grid>
      );
    } else {
      return <p>Empty</p>;
    }
  }
}

export default orders;
