import React, { Component } from "react";
import { Card } from "semantic-ui-react";
import {withRouter} from 'react-router-dom'

export class catagory extends Component {
  render() {
    return (
      <Card
        link
        size="mini"
        color="orange"
        onClick={() => {
            this.props.history.push(`/category/${this.props.item.name}`)
        }}
        header={this.props.item.name}
        meta={`${this.props.item.quantity} Products`}
        image={this.props.item.imageURL}
      ></Card>
    );
  }
}

export default withRouter(catagory);
