import React, { Component } from "react";
import { Grid, Header, Dimmer, Loader } from "semantic-ui-react";
import AddCatagory from "./addCat";
import { connect } from "react-redux";
import { getCatagories } from "../../../Redux/Catagory/catagory.actions";
import PropTypes from 'prop-types'
import ViewCat from './viewCat'

export class Catagory extends Component {

  static propTypes = {
    getCatagories: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.getCatagories();
  }

  render() {
    return (
      <Grid columns="equal">
        <Dimmer inverted>
          <Loader inverted />
        </Dimmer>
        <Grid.Column>
          <AddCatagory />
        </Grid.Column>
        <Grid.Column>
          <ViewCat />
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(null, { getCatagories })(Catagory);
