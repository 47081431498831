export const firebaseConfig = {
  apiKey: "AIzaSyDm46nuhAmAr65anSnTO1fRSETKzfZ8-0o",
  authDomain: "edeliver-79c6c.firebaseapp.com",
  databaseURL: "https://edeliver-79c6c.firebaseio.com",
  projectId: "edeliver-79c6c",
  storageBucket: "edeliver-79c6c.appspot.com",
  messagingSenderId: "782226440965",
  appId: "1:782226440965:web:74b4301b6f30f65a0aad6c",
  measurementId: "G-MS8WW5CHPX",
};
