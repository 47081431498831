import React, { Component } from "react";
import { Grid, Segment, Header } from "semantic-ui-react";
import AddNew from "./AddNew";
import ListedProducts from "./ListedProducts";

export class products extends Component {
    state = {
        justSomeState: ""
    }

  render() {
    return (
      <Grid columns={2}>
        <Grid.Column width="10">
          <Header inverted as="h2" attached="top">
            Add a new product
          </Header>
          <Segment attached>
            <AddNew updateComp={this.updateComp} />
          </Segment>
        </Grid.Column>
        <Grid.Column width="6">
          <Header inverted as="h2" attached="top">
            Listed products
          </Header>
          <Segment attached>
            <ListedProducts />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default products;
