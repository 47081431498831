import React, { Component } from "react";
import { Responsive, Search } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'

export class search extends Component {
  state = {
    isLoading: false,
    value: "",
    results: {
      matrix: {
        name: "matrix",
        results: [
          {
            title: "Ernser and Sons",
            description: "Mandatory 5th generation focus group",
            image:
              "https://s3.amazonaws.com/uifaces/faces/twitter/josevnclch/128.jpg",
            price: "$43.22",
            key: 1,
          },
        ],
      },
    },
  };

  static propTypes = {
    products: PropTypes.array.isRequired,
  };

  handleResultSelect = (e, { result }) => {
    console.log(result);
    this.setState({ value: result.title });
    this.props.history.push(`product/${result.id}`)
  };

  // handelSearchChange = (e, { value }) => {
  //   this.setState({ value, isLoading: true });

  //   let checkedCats = [];

  //   let finalResult = [];

  //   let searchRaw = this.props.products.filter((o) => {
  // return (
  //   o.name.toLowerCase().includes(value.toLowerCase()) ||
  //   o.catagory.toLowerCase().includes(value.toLowerCase())
  // );
  //   });

  //   searchRaw.map((result) => {
  //     if (!checkedCats.includes(result.catagory)) {
  //       checkedCats.push(result.catagory);
  //       let catProducts = searchRaw.filter((o) => {
  //         return o.catagory === result.catagory;
  //       });
  //       let allresults = [];
  //       catProducts.map((item) => {
  //         allresults.push({
  // title: item.name,
  // description: item.description,
  // image: item.imageURL,
  // price: `${item.price} ETB`,
  //         });
  //       });

  //       finalResult.push({
  //         [result.catagory]: {
  //           name: result.catagory,
  //           result: allresults,
  //         },
  //       });
  //     }
  //   });
  //   console.log(finalResult);
  //   // this.setState({ isLoading: false, results: finalResult });
  // };

  handelSearchChange = (e, { value }) => {
    this.setState({ value, isLoading: true, results: [] });

    let rawResults = [];
    let handeledResults = [];
    rawResults = this.props.products.filter((o) => {
      return (
        o.name.toLowerCase().includes(value.toLowerCase()) ||
        o.catagory.toLowerCase().includes(value.toLowerCase())
      );
    });

    rawResults.map((item) => {
      handeledResults.push({
        id: item.id,
        title: item.name,
        description: `in ${item.catagory}`,
        image: item.imageURL,
        price: `${item.price} ETB`,
      });
    });

    this.setState({
      results: handeledResults,
      isLoading: false
    });
  };

  render() {
    return (
      <>
        <Responsive
          style={{ width: "70%" }}
          as="div"
          {...Responsive.onlyComputer}
        >
          <Search
            catagory
            placeholder="Search for anything"
            loading={this.state.isLoading}
            input={{ fluid: true }}
            onResultSelect={this.handleResultSelect}
            results={this.state.results}
            value={this.state.value}
            onSearchChange={this.handelSearchChange}
          />
        </Responsive>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.products.products,
});
export default connect(mapStateToProps)(withRouter((search)));
