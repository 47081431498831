import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import Product from "../showProducts/products";

export class because extends Component {
  componentDidMount() {}

  render() {
    return (
      <Segment
        inverted={this.props.inverted}
        style={{ borderRadius: "0", marginTop: "0", marginBottom: "0" }}
      >
        <Header
          inverted={this.props.inverted}
          as="h2"
          style={{ marginBottom: "0" }}
        >
          Because You Viewed {this.props.item.catagory}
          <Header.Subheader>
            Items related to {this.props.item.name}
          </Header.Subheader>
        </Header>
        <Segment
          basic
          style={{
            overflowY: "auto",
            whiteSpace: "nowrap",
            marginLeft: "-1rem",
            marginRight: "-1rem",
            marginTop: "0",
          }}
        >
          {this.props.prod
            .filter((o) => o.catagory === this.props.item.catagory)
            .map((item, i) => {
              return <Product key={item.id} item={item} />;
            })}
        </Segment>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  prod: state.products.products,
});

export default connect(mapStateToProps)(because);
