import React, { Component } from "react";
import { Item, Segment, Header, Icon, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Orders from "./items";


export class allOrders extends Component {
    
  render() {
    return (
      <>
        <Item.Group divided>
          {this.props.newOrders.map((item) => (
              <Orders setSelectedOrder={this.props.setSelectedOrder} item={item} />
          ))}
        </Item.Group>
      </>
    );
  }
}

export default withRouter(allOrders);
