import cart_types from './cart.types'

const initial_state = {cart: []}

const cartReducer = (state = initial_state, action) => {
    switch (action.type) {
        case (cart_types.ADD_TO_CART):
            return {
                ...state,
                cart: [...state.cart, action.payload]
            }
        case (cart_types.REMOVE_FROM_CART):
            return {
                ...state,
                cart: action.payload
            }
        case (cart_types.UPDATE_CART):
            return {
                ...state,
                cart: action.payload
            }
        case (cart_types.EMPTY_CART):
            return initial_state
        default:
            return state
    }
}

export default cartReducer;