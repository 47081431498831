import React, { Component } from "react";
import {
  Header,
  Image,
  List,
  Dropdown,
  Divider,
  Form,
  Button,
  Icon,
  Table,
  Message,
  Segment,
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { firestore } from "../../../Firebase/utils";

let date;
const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};
export class SelectedOrder extends Component {
  state = {
    selectedDriver: null,
    setDriverLoading: false,
    setDriverError: false,
    assigned: false,
  };
  handleChange = (e, { name, value }) => {
    this.setState({ selectedDriver: value, setDriver: false });
    console.table(value);
  };
  handelSubmit = (e) => {
    console.log("here");
    e.preventDefault();
    if (this.state.selectedDriver == null) {
      this.setState({ setDriverError: true });
    } else {
      this.setState({ setDriverLoading: true, setDriver: false });
      let data = {
        address: this.props.selectedOrder.address,
        orderDate: this.props.selectedOrder.orderDate,
        orderedBy: this.props.selectedOrder.orderedBy,
        products: this.props.selectedOrder.products,
        status: "fulfilling",
        deliverdBy: {
          driverId: this.state.selectedDriver.id,
          driverInfo: {
            name: this.state.selectedDriver.displayName,
            phoneNumber: this.state.selectedDriver.phoneNumber,
          },
        },
      };
      firestore
        .collection("orders")
        .doc(this.props.selectedOrder.id)
        .update(data)
        .then(() => {
          this.setState({ setDriverLoading: false, assigned: true });
        })
        .catch((err) => {
          this.setState({ setDriverLoading: false });
          console.log(err);
        });
    }
  };

  render() {
    if (this.props.selectedOrder == null) {
      return (
        <>
          <h3>Select an order to perform some actions on it.</h3>
        </>
      );
    } else {
      date = this.props.selectedOrder.orderDate.toDate();
      let total = 0;
      this.props.selectedOrder.products.map(
        (item) =>
          (total = parseInt(item.price) * parseInt(item.quantity) + total)
      );
      return (
        <>
          <Header as="h2">
            Order by {this.props.selectedOrder.orderedBy.userInfo.name}
            <Header.Subheader>
              Ordered on{" "}
              {new Date(date).toLocaleString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Header.Subheader>
          </Header>
          <br />
          <Divider horizontal>Order Details</Divider>
          <List divided relaxed>
            <List.Item>
              <List.Icon name="key" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Order id </List.Header>
                <List.Description>
                  {this.props.selectedOrder.id}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="map" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Delivery Address</List.Header>
                <List.Description>
                  {this.props.selectedOrder.address.locName}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Customer Phone Number</List.Header>
                <List.Description>
                  0{this.props.selectedOrder.orderedBy.userInfo.phoneNumber}
                </List.Description>
              </List.Content>
            </List.Item>
            {this.props.selectedOrder.status != "pending" && (
              <List.Item>
                <List.Icon name="truck" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header>Deliverd By</List.Header>
                  <List.Description>
                    {this.props.selectedOrder.deliverdBy.driverInfo.name}
                  </List.Description>
                </List.Content>
              </List.Item>
            )}
          </List>
          <br />
          <Divider horizontal>
            {this.props.selectedOrder.status == "delivering"
              ? "Track your shipment"
              : this.props.selectedOrder.status == "fulfilling"
              ? "Order Status"
              : "Assign to Driver"}
          </Divider>
          <br />
          <Header as="h3">
            Order status
            <Header.Subheader>
              {this.props.selectedOrder.status}
            </Header.Subheader>
          </Header>
          {this.props.selectedOrder.status == "delivering" ? (
            <Button
              fluid
              color="black"
              icon="crosshairs"
              content="Track shipment"
            />
          ) : this.props.selectedOrder.status == "fulfilling" ? (
            <Message>
              Once the driver has started his route you can track the order here
            </Message>
          ) : this.state.assigned ? (
            <Segment placeholder>
              <Header icon>
                <Icon color="green" name="check circle" />
                Order Assigned to {this.state.selectedDriver.displayName}
              </Header>
            </Segment>
          ) : (
            <Form loading={this.state.setDriverLoading}>
              <Form.Field>
                <label>
                  {this.props.selectedOrder.status == "fulfilling"
                    ? "Assign this order to another driver"
                    : "Assign to Driver"}
                </label>
                <Dropdown
                  style={{ marginLeft: "0" }}
                  placeholder="Select Driver"
                  fluid
                  search
                  selection
                  onChange={this.handleChange}
                  options={this.props.driverOptions}
                  error={this.state.setDriverError}
                />
              </Form.Field>
              <Button fluid onClick={this.handelSubmit} color="black">
                <Icon name="add circle" /> Assign to Driver
              </Button>
            </Form>
          )}
          <br />
          <Divider horizontal>Order summary</Divider>
          <Table celled>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>
                  <Header as="h4">
                    Total Products
                    <Header.Subheader>
                      {this.props.selectedOrder.products.length} Products
                    </Header.Subheader>
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell>
                  <Header as="h4">
                    Total
                    <Header.Subheader>
                      {formatMoney(total)} ETB
                    </Header.Subheader>
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>SubTotal</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.props.selectedOrder.products.map((item) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <Link>
                        <Header
                          as="h4"
                          onClick={() =>
                            this.props.history.push(`/product/${item.prodId}`)
                          }
                          image
                        >
                          <Image src={item.imageURL} rounded size="mini" />
                          <Header.Content>
                            {item.name}
                            <Header.Subheader>{item.category}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{item.quantity}</Table.Cell>
                    <Table.Cell>
                      <Header as="h4">
                        {formatMoney(item.quantity * item.price)} ETB{" "}
                        <Header.Subheader>{`${item.quantity} x ${item.price} ETB`}</Header.Subheader>{" "}
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      );
    }
  }
}

export default withRouter(SelectedOrder);
