import React, { Component } from "react";
import { Item, Header } from "semantic-ui-react";

export class order extends Component {
  render() {
    return (
      <Item>
        <Item.Image size="tiny" src="/images/wireframe/image.png" />

        <Item.Content>
          <Header as="a">
            Header
            <Header.Subheader>Sub Header</Header.Subheader>
          </Header>

          <Item.Meta>Description</Item.Meta>
          <Item.Description>
            {/* <Image src="/images/wireframe/short-paragraph.png" /> */}
          </Item.Description>
          <Item.Extra>Additional Details</Item.Extra>
        </Item.Content>
      </Item>
    );
  }
}

export default order;
