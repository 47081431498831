import React, { Component } from "react";
import { Segment, Header, Card, Icon, CardHeader, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CatCard from "./catagory";

export class Catagory extends Component {
  state = { loading: false, value: "" };
  static propTypes = {
    products: PropTypes.object.isRequired,
  };
  cards;
  cat = [];
  catIsDone = false;

  renderCat = () => {
    if (!this.catIsDone) {
      let found;
      this.props.products.map((item, iterator) => {
        this.cat.map((cat, i) => {
          if (cat.name === item.catagory) {
            this.cat[i] = {
              name: item.catagory,
              imageURL: item.imageURL,
              quantity: this.cat[i].quantity + 1,
            };
            console.log(i);
            found = true;
          }
        });
        if (!found) {
          this.cat = [
            ...this.cat,
            { name: item.catagory, imageURL: item.imageURL, quantity: 1 },
          ];
        }
        found = false;
      });
    }
    console.log(this.cat);
  };

  render4Cat = () => {
    let theFirst3 = this.cat.slice(0, 3);
    return theFirst3;
  }

  render() {
    return (
      <>
        <Segment
          style={{ borderRadius: "0", marginTop: "0", marginBottom:"0" }}
          loading={this.state.loading}
          inverted
        >
          <Header inverted as="h2">
            Choose from our various categories
            <Header.Subheader>
              We have various options to satisfy your construction needs
            </Header.Subheader>
          </Header>
          <Card.Group stackable itemsPerRow={4}>
            {this.renderCat()}
            {this.render4Cat().map((item, iterator) => {
              return <CatCard key={iterator} item={item} />;
            })}
            <Card
              link
              size="mini"
              color="orange"
              onClick={() => {
                this.props.history.push(`/category/${this.props.item.name}`);
              }}
            >
              <Card.Content textAlign="center">
                <Icon style={{marginTop: "3rem", marginBottom: "1rem"}} size="massive" name="tag" />
                <Card.Header>{`${
                  this.cat.length - 3
                  } More Catagories are Available`}</Card.Header>
                <Card.Description>More Catagories for you to choose from</Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Button fluid color="black">
                  {`See all ${this.cat.length} Catagories`}
                  <Icon name="chevron right"/>
                </Button>
              </Card.Content>
            </Card>
          </Card.Group>
        </Segment>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.products.products,
});
export default connect(mapStateToProps)(Catagory);
