import catagory_types from "./catagory.types";
import { firestore } from "../../Firebase/utils";

export const setCatagories = (catagories) => (dispatch) => {
  dispatch({
    type: catagory_types.SET_CATAGORIES,
    payload: catagories,
  });
};

export const getCatagories = () => (dispatch) => {
  firestore
    .collection("catagory")
    .doc("catagory")
    .get()
    .then((doc) => {
      dispatch({
        type: catagory_types.GET_CATAGORIES,
        payload: doc.data().catagory,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
