import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import Product from "../showProducts/products";

export class recentlySeen extends Component {
  recent = [];
  componentWillMount() {
    this.recent = JSON.parse(localStorage.getItem("recent"));
  }

  render() {
    return (
      <>
        {this.recent ? (
          <Segment
            inverted
            style={{ borderRadius: "0", marginTop: "0", marginBottom: "0" }}
          >
            <Header inverted as="h2" style={{ marginBottom: "0" }}>
              Recently Viewed Products
              <Header.Subheader>
                You check out these out recently, we have saved them incase you
                might be interested again.
              </Header.Subheader>
            </Header>
            <Segment
              basic
              style={{
                overflowY: "auto",
                whiteSpace: "nowrap",
                marginLeft: "-1rem",
                marginRight: "-1rem",
                marginTop: "0",
              }}
            >
              {this.recent.reverse().map((item) => {
                return <Product key={item.id} item={item} />;
              })}
              
            </Segment>
          </Segment>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  prod: state.products.products,
});

export default connect(mapStateToProps)(recentlySeen);
