import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Button,
  Input,
  Label,
  Image,
  Icon,
  Grid,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { addToCart } from "../Redux/Cart/cart.actions";

export class ListProducts extends Component {
  state = {
    quantity: 1,
    productId: this.props.item.id,
    image: this.props.item.imageURL,
    price: this.props.item.price,
    catagory: this.props.item.catagory,
    product: this.props.item,
    addedToCart: false,
  };
  formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  };
  handelAddToCart = () => {
    this.props.addToCart({
      prodId: this.state.product.id,
      name: this.state.product.name,
      quantity: this.state.quantity,
      category: this.state.product.catagory,
      price: this.state.product.price,
      orderDate: new Date(),
      imageURL: this.state.product.imageURL,
    });
    this.setState({ addedToCart: true });
  };
  handelValueChange = (e) => {
    if (
      !(e.target.value > this.state.product.quantity) &&
      !(e.target.value.length == 0)
    ) {
      this.setState({ quantity: e.target.value });
    } else if (e.target.value.length == 0) {
      this.setState({ quantity: 0 });
    }
  };

  handelQuantityChange = (type) => {
    if (
      type === "plus" &&
      !(this.state.quantity >= this.state.product.quantity)
    ) {
      console.log(type);
      this.setState((prevState) => {
        return { quantity: prevState.quantity + 1 };
      });
    } else if (type === "minus" && !(this.state.quantity <= 0)) {
      console.log(type);
      this.setState((prevState) => {
        return { quantity: prevState.quantity - 1 };
      });
    } else {
      console.log("here");
    }
  };
  render() {
    return (
      <>
        <Card as="a" raised>
          <Image
            src={this.props.item.imageURL}
            wrapped
            ui={false}
            onClick={() =>
              this.props.history.push(`/product/${this.props.item.id}`)
            }
          />
          <Card.Content
            onClick={() =>
              this.props.history.push(`/product/${this.props.item.id}`)
            }
          >
            <Card.Header>{this.props.item.name}</Card.Header>
            {/* <Card.Meta>
              <span className="date">Joined in 2015</span>
            </Card.Meta> */}
            <Card.Description>{this.props.item.description}</Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Grid columns={1} verticalAlign="middle">
              <Grid.Row verticalAlign="middle">
                <Grid.Column width="8" verticalAlign="middle">
                  <Input
                    labelPosition="right"
                    style={{ width: "5rem" }}
                    value="0"
                    action
                    type="text"
                  >
                    <Label
                      color="teal"
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: ".2rem",
                      }}
                      onClick={() => this.handelQuantityChange("minus")}
                      as="a"
                    >
                      <Icon name="minus" />
                    </Label>
                    <input
                      onChange={this.handelValueChange}
                      value={this.state.quantity}
                      style={{ textAlign: "center" }}
                    />
                    <Label
                      color="teal"
                      style={{
                        paddingLeft: "1rem",
                        paddingRight: ".2rem",
                      }}
                      onClick={() => this.handelQuantityChange("plus")}
                      as="a"
                    >
                      <Icon name="plus" />
                    </Label>
                  </Input>
                </Grid.Column>
                <Grid.Column width="8" verticalAlign="middle">
                  {this.state.addedToCart ? (
                    <Button
                      fluid
                      disabled
                      color="black"
                      icon="check circle"
                      content="Added To Cart"
                    />
                  ) : (
                    <Button
                      animated="fade"
                      fluid
                      color="black"
                      onClick={this.handelAddToCart}
                    >
                      <Button.Content visible>Add to Cart</Button.Content>
                      <Button.Content hidden>
                        {`Total ${this.formatMoney(
                          this.state.quantity * this.state.product.price
                        )} ETB`}
                      </Button.Content>
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
        </Card>
      </>
    );
  }
}

export default connect(null, { addToCart })(withRouter(ListProducts));
