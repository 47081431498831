import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { Segment, Form, Input, Label, Message, Icon, Button } from 'semantic-ui-react';
import {firestore} from '../Firebase/utils'

export class AddPhone extends Component {
    static propTypes = {
        currentUser: PropTypes.object.isRequired
    }
    state = {
        selectAdd: false,
        phoneNumber: "",
        loading: false,
        err: false
    }
    handelSubmit = (e) => {
        e.preventDefault()
        this.setState({loading: true})
        firestore.collection("users").doc(this.props.currentUser.id).update({
            createdDate: this.props.currentUser.createdDate,
            displayName: this.props.currentUser.displayName,
            email: this.props.currentUser.email,
            phoneNumber: this.state.phoneNumber,
            userRoles: this.props.currentUser.userRoles,
        }).then(() => {
            this.setState({ loading: false })
            this.props.forUpdate();
        }).catch(() => {
            this.setState({ err: true });
        })
    }
    handelChange = (e) => {
    this.setState({ phoneNumber: e.target.value });
}
    render() {
        if (this.state.selectAdd) {
            return (
              <>
                <Segment loading={this.state.loading} fluid>
                  <Form onSubmit={this.handelSubmit}>
                    <Form.Field>
                      <label>Phone Number</label>
                      <Input labelPosition="left" onChange={this.handelChange} placeholder="9XXXXXXXX">
                        <Label>+251</Label>
                        <input type="tel" />
                      </Input>
                    </Form.Field>
                    <Button fluid type="submit" onClick={this} color="black" icon="check" content="Add Phone number" />
                  </Form>
                </Segment>
              </>
            );
        } else {
            return (
              <>
                    <Message negative icon>
                        <Icon name="warning circle" />
                  <Message.Header>
                    You need to add your phone number first
                  </Message.Header><br/>
                  <Button onClick={()=>{this.setState({selectAdd: true})}} content="Add a phone number"/>
                </Message>
              </>
            );
        }
    }
}
const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
})
export default connect(mapStateToProps)(AddPhone)