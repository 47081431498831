import React, { Component, createRef } from "react";
import {
  Grid
} from "semantic-ui-react";
import Products from './RightSide/products/products'
import "./adminStyle.css";
import Staff from './RightSide/staff'
import Catagory from './RightSide/catagory'
import Orders from './RightSide/orders'

export class admin extends Component {
    state = { activeItem: "orders" };

  handleItemClick = (e) => this.setState({ activeItem: e.target.name });

  renderRight = () => {
    switch (this.state.activeItem) {
      case "orders":
        return <Orders />;
      case "catagory":
        return <Catagory/>;
      case "products":
        return <Products/>;
      case "newAdmin":
        return <Staff />;
      default:
        return <h1>Choose an Option</h1>;
    }
  };

  render() {
    const { activeItem } = this.state;
    return (
      <>
        <Grid celled columns={2}>
          <Grid.Column width="3">
            <div className="ui vertical fluid menu">
              <a
                className={activeItem == "orders" ? "active teal item" : "item"}
                onClick={this.handleItemClick}
                name="orders"
              >
                <i className="tasks icon"></i>
                Mange Orders
              </a>
              <a
                className={
                  activeItem == "catagory" ? "active teal item" : "item"
                }
                onClick={this.handleItemClick}
                name="catagory"
              >
                <i className="plus icon"></i>Add a new catagory
              </a>
              <a
                className={
                  activeItem == "products" ? "active teal item" : "item"
                }
                onClick={this.handleItemClick}
                name="products"
              >
                <i className="archive icon"></i>Manage Products
              </a>
              <a
                className={
                  activeItem == "newAdmin" ? "active teal item" : "item"
                }
                onClick={this.handleItemClick}
                name="newAdmin"
              >
                <i className="users icon"></i>Manage Staff
              </a>
            </div>
          </Grid.Column>
          <Grid.Column width="13">{this.renderRight()}</Grid.Column>
        </Grid>
      </>
    );
  }
}

export default admin;
