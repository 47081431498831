import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import L, { LatLng } from "leaflet";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import Search from "react-leaflet-search";
import { Button, Header, Card, Icon } from "semantic-ui-react";
import "./map.css";

let myIcon = L.icon({
  iconUrl:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

const MyMarker = (props) => {
  const initMarker = (ref) => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };

  return <Marker ref={initMarker} {...props} />;
};

export class AddAddress extends Component {
  static propTypes = {};
  state = {
    selected: false,
    lat: 8.946022,
    lng: 38.747102,
    zoom: 14,
    searchLatLng: null,
    info: "",
    loadingLoc: false,
    currentPos: {
      lat: 0,
      lng: 0,
      showCurrent: false,
      loadingLoc: false,
      info: "",
    },
  };

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(async (position) => {
      this.setState({
        currentPos: {
          lng: position.coords.longitude,
          lat: position.coords.latitude,
          showCurrent: true,
          loadingLoc: true,
        },
      });
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
      );
      const myJson = await response.json(); //extract JSON from the http response
      this.setState({
        currentPos: {
          lng: position.coords.longitude,
          lat: position.coords.latitude,
          showCurrent: true,
          loadingLoc: false,
          info: myJson.display_name,
        },
      });
    });
  }

  mapRef = createRef();

  handleClick = async (e) => {
    this.setState({
      selected: true,
      lat: e.latlng.lat,
      lng: e.latlng.lng,
      loadingLoc: true,
      zoom: 15,
      currentPos: {
        lng: this.state.currentPos.lat,
        lat: this.state.currentPos.lng,
        showCurrent: false,
        loadingLoc: false,
        info: this.state.currentPos.info,
      },
    });
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${e.latlng.lat}&lon=${e.latlng.lng}`
    );
    const myJson = await response.json(); //extract JSON from the http response
    this.setState({
      loadingLoc: false,
      info: myJson.display_name,
    });
  };

  handleLocationFound = (e: Object) => {
    this.setState({ searchLatLng: e.latlng });
    console.log(e.latlng);
  };

  customPopup(SearchInfo: any) {
    return (
      <Popup>
        <Header as="h3">Select this as your shipping address?</Header>
        <Button fluid positive content="Yes" />
      </Popup>
    );
  }

  render() {
    const position = [this.state.lat, this.state.lng];
    return (
      <>
        <Map
          style={{ height: "70vh" }}
          center={position}
          zoom={this.state.zoom}
          onClick={this.handleClick}
          onLocationfound={this.handleLocationFound}
          ref={this.mapRef}
          search={
            this.state.searchLatLng
              ? new LatLng(
                  this.state.searchLatLng.lat,
                  this.state.searchLatLng.lng
                )
              : undefined
          }
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Search
            position="topleft"
            providerOptions={{
              searchBounds: [
                new LatLng(8.9307, 38.67738),
                new LatLng(9.09549, 38.84492),
              ],
              region: "et",
            }}
            onChange={(SearchInfo) => {
              this.setState({
                selected: true,
                lat: SearchInfo.latLng.lat,
                lng: SearchInfo.latLng.lng,
                info: SearchInfo.info,
                zoom: 15,
                currentPos: {
                  lng: this.state.currentPos.lat,
                  lat: this.state.currentPos.lng,
                  showCurrent: false,
                  loadingLoc: false,
                  info: this.state.currentPos.info,
                },
              });
              console.log(SearchInfo);
            }}
            closeResultsOnClick={true}
            zoom={5}
            showMarker={true}
          >
            {(info) => (
              null
            )}
          </Search>
          {this.state.selected && (
            <MyMarker position={position} icon={myIcon}>
              <Popup>
                <Header as="h3">
                  Select this as your shipping address?
                  <Header.Subheader>
                    {this.state.loadingLoc
                      ? "Loading location name, Please wait."
                      : this.state.info}
                  </Header.Subheader>
                </Header>
                <Button.Group fluid>
                  <Button
                    positive
                    onClick={() =>
                      this.props.setAddy({
                        gotAddy: true,
                        locName: this.state.info,
                        latlng: [this.state.lat, this.state.lng],
                      })
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    negative
                    onClick={() => this.setState({ selected: false })}
                  >
                    No
                  </Button>
                </Button.Group>
              </Popup>
            </MyMarker>
          )}
          {this.state.currentPos.showCurrent && (
            <MyMarker
              position={[this.state.currentPos.lat, this.state.currentPos.lng]}
              icon={myIcon}
            >
              <Popup>
                <Header as="h3">
                  You are currently here. Select this as your shipping address?
                  <Header.Subheader>
                    {this.state.currentPos.loadingLoc
                      ? "Loading location name, Please wait."
                      : this.state.currentPos.info}
                  </Header.Subheader>
                  <br />
                  <Header.Subheader>
                    <Icon name="warning circle" />
                    Make sure this is your current location.
                  </Header.Subheader>
                </Header>
                <Button.Group fluid>
                  <Button
                    positive
                    onClick={() =>
                      this.props.setAddy({
                        gotAddy: true,
                        locName: this.state.currentPos.info,
                        latlng: [
                          this.state.currentPos.lat,
                          this.state.currentPos.lng,
                        ],
                      })
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    negative
                    onClick={() =>
                      this.setState({
                        currentPos: {
                          lng: this.state.currentPos.lat,
                          lat: this.state.currentPos.lng,
                          showCurrent: false,
                          loadingLoc: false,
                          info: this.state.currentPos.info,
                        },
                      })
                    }
                  >
                    No
                  </Button>
                </Button.Group>
              </Popup>
            </MyMarker>
          )}
        </Map>
      </>
    );
  }
}

export default AddAddress;
