import React, { Component } from "react";
import {
  Segment,
  Header,
  Button,
  Checkbox,
  Form,
  Select,
  Icon,
  Divider,
  Label,
  Input,
} from "semantic-ui-react";

import { auth, handelUserProfile, signInWithGoogle } from "./../Firebase/utils";

const options = [
  { key: "m", text: "Male", value: "male" },
  { key: "f", text: "Female", value: "female" },
];

const initialErrState = {
  fnameErr: { err: false, msg: "" },
  lnameErr: { err: false, msg: "" },
  emailErr: { err: false, msg: "" },
  passErr: { err: false, msg: "" },
  confPassErr: { err: false, msg: "" },
  ToCErr: { err: false, msg: "" },
  phoneErr: { err: false, msg: "" },
};
export class Register extends Component {
  state = {
    ToC: false,
    fname: "",
    lname: "",
    email: "",
    password: "",
    confPass: "",
    phone: "",
    loading: false,
    ...initialErrState,
  };

  handelSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    let foundErr = false;

    if (this.state.password !== this.state.confPass) {
      this.setState({
        confPassErr: { err: true, msg: "The two passwords don't match!" },
      });
      foundErr = true;
    } else {
      this.setState({ confPassErr: initialErrState.confPassErr });
    }
    if (this.state.fname.length <= 1) {
      this.setState({
        fnameErr: { err: true, msg: "Please enter your first name" },
      });
      foundErr = true;
    } else {
      this.setState({ fnameErr: initialErrState.fnameErr });
    }
    if (this.state.lname.length <= 1) {
      this.setState({
        lnameErr: { err: true, msg: "Please enter your last name" },
      });
      foundErr = true;
    } else {
      this.setState({ lnameErr: initialErrState.lnameErr });
    }
    if (this.state.email.length <= 1) {
      this.setState({
        emailErr: { err: true, msg: "Please enter your email" },
      });
      foundErr = true;
    } else {
      this.setState({ emailErr: initialErrState.emailErr });
    }
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({
        emailErr: {
          err: true,
          msg: "Please enter a valid email eg. example@example.com",
        },
      });
      foundErr = true;
    } else {
      this.setState({ emailErr: initialErrState.emailErr });
    }
    if (this.state.password.length <= 6) {
      this.setState({
        passErr: {
          err: true,
          msg: "Your password must be 6 characters or longer",
        },
      });
      foundErr = true;
    } else {
      this.setState({ passErr: initialErrState.passErr });
    }
    if (!this.state.ToC) {
      this.setState({
        ToCErr: {
          err: true,
          msg: "You must agree to the terms and conditions",
        },
      });
      foundErr = true;
    } else {
      this.setState({ ToCErr: initialErrState.ToCErr });
    }
    if (!foundErr) {
      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          this.state.email,
          this.state.password
        );
        await handelUserProfile(user, {
          displayName: this.state.fname + " " + this.state.lname,
        });
        console.log("done");
      } catch (err) {
        console.log(err);
      }
    }
    this.setState({ loading: false });
  };

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {
      fnameErr,
      lnameErr,
      emailErr,
      passErr,
      confPassErr,
      ToCErr,
      phoneErr,
    } = this.state;
    return (
      <Segment textAlign="center">
        <Segment textAlign="left" padded raised>
          <Header as="h2">
            <Icon name="signup" />
            <Header.Content>Sign-up for an account</Header.Content>
            {/* <Header.Subheader>Fill in the forms to create your account.</Header.Subheader> */}
          </Header>
          <Divider hidden />
          <Form onSubmit={this.handelSubmit} loading={this.state.loading}>
            <Form.Group widths="equal">
              <Form.Input
                error={fnameErr.err ? fnameErr.msg : undefined}
                fluid
                label="First name"
                placeholder="First name"
                id="form-input-first-name"
                name="fname"
                value={this.state.fname}
                onChange={this.handelChange}
              />
              <Form.Input
                error={lnameErr.err ? lnameErr.msg : undefined}
                value={this.state.lname}
                fluid
                label="Last name"
                placeholder="Last name"
                id="form-input-last-name"
                name="lname"
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                error={emailErr.err ? emailErr.msg : undefined}
                value={this.state.email}
                fluid
                label="Email"
                placeholder="Email"
                id="form-input-email"
                name="email"
                onChange={this.handelChange}
              />
              <Form.Field>
                <label>Phone Number</label>
                <Input labelPosition="left" placeholder="9XXXXXXXX">
                  <Label>+251</Label>
                  <input />
                </Input>
              </Form.Field>
            </Form.Group>
            <Form.Input
              error={passErr.err ? passErr.msg : undefined}
              value={this.state.password}
              fluid
              label="Password"
              placeholder="Password"
              id="form-input-password"
              name="password"
              onChange={this.handelChange}
            />
            <Form.Input
              error={confPassErr.err ? confPassErr.msg : undefined}
              value={this.state.confPass}
              fluid
              label="Confirm Password"
              placeholder="Confirm Password"
              id="form-input-confirm-password"
              name="confPass"
              onChange={this.handelChange}
            />
            <Form.Checkbox
              label="I agree to the Terms and Conditions"
              error={
                ToCErr.err
                  ? {
                      content: "You must agree to the terms and conditions",
                      pointing: "left",
                    }
                  : undefined
              }
              onChange={() => {
                this.setState({ ToC: !this.state.ToC });
              }}
            />
            <Button icon type="submit" labelPosition="right" color="black">
              Continue
              <Icon name="right arrow" />
            </Button>
            <Divider horizontal>Or Sign in with Social Media</Divider>
            <div style={{ textAlign: "center" }}>
              <Button color="facebook" style={{ marginTop: ".5rem" }}>
                <Icon name="facebook" /> Sign in with Facebook
              </Button>
              <Button
                color="google plus"
                style={{ marginTop: ".5rem" }}
                onClick={signInWithGoogle}
              >
                <Icon name="google" />
                Sign in with Google
              </Button>
            </div>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default Register;
