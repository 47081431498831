import React, { Component } from "react";
import {
  Input,
  CardGroup,
  Card,
  Icon,
  Button,
  Modal,
  Header,
} from "semantic-ui-react";
import DeleteModal from "./DeleteModal";
import EditModal from "./EditModal";

export class product extends Component {
  state = {
    showDeleteModal: false,
    showEditModal: false,
  };
  hideDeleteModal = (show) => {
    this.setState({ showDeleteModal: show });
  };
  hideEditModal = (show) => {
    this.setState({ showEditModal: show });
  };
  extra = (
    <>
      <Button.Group fluid>
        <Button
          basic
          color="blue"
          onClick={() => {
            this.hideEditModal(true);
          }}
        >
          <Icon name="pencil" color="blue" />
          Edit
        </Button>
        <Button
          basic
          color="red"
          onClick={() => {
            this.hideDeleteModal(true);
          }}
        >
          <Icon name="trash" color="red" />
          Delete
        </Button>
      </Button.Group>
    </>
  );
  render() {
    return (
      <>
        <DeleteModal
          showModal={this.state.showDeleteModal}
          hideModal={this.hideDeleteModal}
          id={this.props.id}
          itemName={this.props.itemName}
        />
        <EditModal
          showModal={this.state.showEditModal}
          hideModal={this.hideEditModal}
          item={this.props.item}
        />
        <Card
          image={this.props.imgUrl}
          header={this.props.itemName}
          meta={`Quantity: ${this.props.qunatity} Price: ${this.props.price} ETB`}
          description={this.props.description}
          extra={this.extra}
        />
      </>
    );
  }
}

export default product;
