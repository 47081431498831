import productsTypes from './products.types'

export const addProductStart = productData => ({
    type: productsTypes.ADD_NEW_PRODUCT_START,
    payload: productData
}) 

export const fetchProductsStart = () => ({
    type: productsTypes.FETCH_PRODUCT_START
});

export const setProducts = (products) => dispatch => {
    dispatch({
        type: productsTypes.SET_PRODUCT,
        payload: products
    })
};