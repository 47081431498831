import React, { Component } from "react";
import { Modal, Header, Icon, Button } from "semantic-ui-react";
import { firestore } from "../../../Firebase/utils";

export class DeleteModal extends Component {
  state = { loading: false, error: false, success: false };

  deleteItemForever =()=> {
    this.setState({ loading: true, error: false, success: false });
    firestore
      .collection("products")
      .doc(this.props.id)
      .delete()
      .then(() => {
        this.setState({ loading: false, error: false, success: true });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, error: true, success: false });
      });
  }

  confirmChoice = (
    <>
      <Header icon>
        <Icon color="red" name="trash alternate" />
        {`Are you sure you want to delete ${this.props.itemName}?`}
      </Header>
      <Modal.Content>
        <p>This process can not be UNDONE!</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="green"
          inverted
          onClick={() => {
            this.props.hideModal(false);
            this.setState({ loading: true, error: false, success: false });
          }}
        >
          <Icon name="remove" /> No
        </Button>
        <Button color="red" inverted onClick={this.deleteItemForever}>
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </>
  );
  loading = (
    <>
      <Header icon>
        <Icon name="notched circle" loading />
        Just one second
      </Header>
      <Modal.Content>
        <p>Finishing your request please wait a second.</p>
      </Modal.Content>
    </>
  );
  success = (
    <>
      <Header icon>
        <Icon color="green" name="circle check" />
        {`Deleted Successfully`}
      </Header>
      <Modal.Actions>
        <Button
          fluid
          color="green"
          onClick={() => {
            this.props.hideModal(false);
            this.setState({ loading: true, error: false, success: false });
          }}
        >
          <Icon name="check" /> Done
        </Button>
      </Modal.Actions>
    </>
  );
  error = (
    <>
      <Header icon>
        <Icon color="green" name="times circle outline" />
        Oopps.... Something went wrong
      </Header>
      <Modal.Content>
        <p>
          Something went wrong And we couldn't figure out what it was. Refresh
          the page and try again.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          fluid
          color="red"
          onClick={() => {
            this.props.hideModal(false);
            this.setState({ loading: true, error: false, success: false });
          }}
        >
          <Icon name="times" /> Close
        </Button>
      </Modal.Actions>
    </>
  );

  modalRender = () => {
    if (this.state.loading) {
      return this.loading;
    } else if (
      this.state.loading == false &&
      this.state.error == false &&
      this.state.success == false
    ) {
      return this.confirmChoice;
    } else if (this.state.success) {
      return this.success;
    } else if (this.state.error) {
      return this.error;
    } else {
      return (
        <>
          <Modal.Actions>
            <Button
              fluid
              color="red"
              onClick={() => this.props.hideModal(false)}
            >
              <Icon name="times" /> Close
            </Button>
          </Modal.Actions>
        </>
      );
    }
  };
  render() {
    return (
      <Modal centered basic open={this.props.showModal} size="mini">
        {this.modalRender()}
      </Modal>
    );
  }
}

export default DeleteModal;
