import React, { Component } from "react";
import { List, Button, Icon } from "semantic-ui-react";
import DeleteModal from "./deleteModal";

const options = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};
export class CatList extends Component {
  state = {
    showDeleteModal: false,
  };
  hideDeleteModal = (show) => {
    this.setState({ showDeleteModal: show });
  };
  formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", options);
  };
  render() {
    return (
      <>
        <DeleteModal
          item={this.props.item}
          hideModal={this.hideDeleteModal}
          showModal={this.state.showDeleteModal}
        />
        <List.Item>
          <List.Content floated="right">
            <Button
              color="red"
              basic
              icon
              onClick={() => {
                this.hideDeleteModal(true);
              }}
            >
              <Icon color="red" name="trash" />
            </Button>
          </List.Content>
          <List.Header>{this.props.item.name}</List.Header>
          {this.props.item.date.toDate().toLocaleDateString("en-US", options)}
        </List.Item>
      </>
    );
  }
}

export default CatList;
