import React, { Component } from "react";
import {
  Search,
  Input,
  CardGroup,
  Card,
  Icon,
  Button,
  Modal,
  Header,
} from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Product from "./product";

const extra = (
  <>
    <Button.Group fluid>
      <Button basic color="blue">
        <Icon name="pencil" color="blue" />
        Edit
      </Button>
      <Button basic color="red" style={{ float: "right" }}>
        <Icon name="trash" color="red" />
        Delete
      </Button>
    </Button.Group>
  </>
);

let prod 

export class ListedProducts extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
  };
  state = {
    search: "",
    };

    componentDidMount() {
        prod = this.props.products.map((item) => (
            <Product
                key={item.id}
                id={item.id}
                itemName={item.name}
                qunatity={item.quantity}
                description={item.description}
                imgUrl={item.imageURL}
                cat={item.catagory}
                price={item.price}
                extra={this.extra}
                item={item}
            />
        ))
        this.setState({ search: "",})

        console.log(prod)
    }

  handelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length === 0 && this.state.search.length === 0) {
      prod = this.props.products.map((item) => (
        <Product
          key={item.id}
          id={item.id}
          itemName={item.name}
          qunatity={item.quantity}
          description={item.description}
          imgUrl={item.imageURL}
          cat={item.catagory}
          price={item.price}
          extra={this.extra}
          item={item}
        />
      ));
    } else {
      prod = this.props.products
        .filter(function (item) {
          if (
            item.name.includes(e.target.value) ||
            item.description.includes(e.target.value)
          ) {
            return true;
          } else return false;
        })
        .map((item) => (
          <Product
            key={item.id}
            id={item.id}
            itemName={item.name}
            qunatity={item.quantity}
            description={item.description}
            imgUrl={item.imageURL}
            cat={item.catagory}
            price={item.price}
            extra={this.extra}
            item={item}
          />
        ));
    }
  };
  results = () => {
    if (this.state.search.length === 0) {
      let prod = this.props.products.map((item) => (
        <Product
          key={item.id}
          id={item.id}
          itemName={item.name}
          qunatity={item.quantity}
          description={item.description}
          imgUrl={item.imageURL}
          cat={item.catagory}
          price={item.price}
          extra={this.extra}
          item={item}
        />
      ));
      return prod;
    } else {
      let prod = this.props.products
        .filter(function (item) {
          if (
            item.name.includes(this.state.search) ||
            item.description.includes(this.state.search)
          ) {
            return true;
          } else return false;
        })
        .map((item) => (
          <Product
            key={item.id}
            id={item.id}
            itemName={item.name}
            qunatity={item.quantity}
            description={item.description}
            imgUrl={item.imageURL}
            cat={item.catagory}
            price={item.price}
            extra={this.extra}
            item={item}
          />
        ));
      return prod;
    }
  };
  prod = this.props.products.map((item) => (
    <Product
      key={item.id}
      id={item.id}
      itemName={item.name}
      qunatity={item.quantity}
      description={item.description}
      imgUrl={item.imageURL}
      cat={item.catagory}
      price={item.price}
      extra={this.extra}
      item={item}
    />
  ));

  render() {
    return (
      <>
        <Input
          icon="search"
          onChange={this.handelChange}
          name="search"
          value={this.state.search}
          fluid
          placeholder="Search..."
        />
        <br />
        <CardGroup itemsPerRow={1}>{prod}</CardGroup>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.products.products,
});
export default connect(mapStateToProps)(ListedProducts);
