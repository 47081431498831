import { createStore, applyMiddleware } from 'redux'
// import logger from 'redux-logger';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';

import rootReducer from './rootReducer'

export const middlewares = [thunk]

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
export default store;