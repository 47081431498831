import React, { Component } from "react";
import {
  Header,
  Segment,
  Popup,
  Icon,
  Form,
  Button,
  Message,
} from "semantic-ui-react";
import { firestore } from "../../../Firebase/utils";

export class DriverStaff extends Component {
  state = {
    email: "",
    loading: false,
    error: { err: false, msg: "" },
    success: false,
  };

  handelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handelSubmit = (e) => {
    e.preventDefault();
    this.setState({
      success: false,
      loading: true,
      error: { err: false, msg: "" },
    });
    firestore
      .collection("users")
      .where("email", "==", this.state.email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size !== 0) {
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            if (item.userRoles.includes("driver") === false) {
                item.userRoles = [...item.userRoles, 'driver'];
              firestore
                .collection("users")
                .doc(doc.id)
                .update(item)
                .then((res) =>
                  this.setState({
                    success: true,
                    loading: false,
                    error: { err: false, msg: "" },
                  })
                )
                .catch((err) => {
                  console.log(err);
                  this.setState({
                    success: false,
                    loading: false,
                    error: {
                      err: true,
                      msg: "Oops... Something went wrong! Try again please.",
                    },
                  });
                });
            } else {
              this.setState({
                success: false,
                loading: false,
                error: { err: true, msg: "User is already an driver" },
              });
            }
          });
        } else {
          this.setState({
            success: false,
            loading: false,
            error: {
              err: true,
              msg: `There is no user with the email address ${this.state.email}`,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          success: false,
          loading: false,
          error: {
            err: true,
            msg: "Oops... Something went wrong! Try again please.",
          },
        });
      });
  };
  render() {
    return (
      <>
        <Header inverted as="h2" attached="top">
          Add a new driver
          <Popup
            trigger={
              <Icon
                color="grey"
                style={{ float: "right" }}
                name="question circle"
              />
            }
            content="Search for the user you want to make a driver using thier email address."
            inverted
            position="bottom right"
            header="How dose this work?"
          />
        </Header>
        <Segment attached loading={this.state.loading}>
          <Form onSubmit={this.handelSubmit}>
            <Form.Field>
              <label>
                Enter the email of the user you want to make a driver
              </label>
              <input
                placeholder="Email"
                type="email"
                value={this.state.email}
                onChange={this.handelChange}
                name="email"
              />
              <Button
                icon
                type="submit"
                labelPosition="right"
                fluid
                color="blue"
                style={{ marginTop: "1rem" }}
              >
                Add driver
                <Icon name="plus" />
              </Button>
            </Form.Field>
          </Form>
          <Message
            error
            hidden={!this.state.error.err}
            content={this.state.error.msg}
          />
          <Message
            positive
            icon="green check circle"
            hidden={!this.state.success}
            content={`Successfully added new driver`}
          />
        </Segment>
      </>
    );
  }
}

export default DriverStaff;
