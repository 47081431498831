import React, { Component } from "react";
import { Header, Segment, Form, Button, Icon, Message } from "semantic-ui-react";
import { firestore } from "../../../Firebase/utils";
import { connect } from "react-redux";
import { setCatagories, getCatagories } from "../../../Redux/Catagory/catagory.actions";

export class AddCat extends Component {
  state = { loading: false, error: false, success: false, catToAdd: "" };

  handelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handelSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true, error: false, success: false });
    let date = new Date();
    let data = [...this.props.catagories, {name: this.state.catToAdd, date: date }];
    firestore
      .collection("catagory")
      .doc("catagory")
      .update({catagory: data})
      .then(() => {
        this.setState({ loading: false, error: false, success: true });
        this.props.getCatagories();
      })
      .catch((err) => { console.log(err); this.setState({ loading: false, error: true, success: false });});
  };
  render() {
    return (
      <>
        <Header as="h2" inverted attached="top">
          Add a new catagory
        </Header>
        <Segment attached loading={this.state.loading}>
          <Form onSubmit={this.handelSubmit}>
            <Form.Field>
              <label>Catagory Name</label>
              <input
                placeholder="Catagory Name"
                onChange={this.handelChange}
                name="catToAdd"
                value={this.state.catToAdd}
              />
            </Form.Field>
            <Button
              icon
              type="submit"
              labelPosition="right"
              color="black"
              fluid
            >
              Add new Catagory
              <Icon name="plus" />
            </Button>
          </Form>
          <Message
            error
            hidden={!this.state.error}
            content="Oopps... something went wrong. Please try again."
          />
          <Message
            positive
            icon="green check circle"
            hidden={!this.state.success}
            content={`New "${this.state.catToAdd}" catagory added.`}
          />
        </Segment>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  catagories: state.catagories.catagories,
});

export default connect(mapStateToProps, { setCatagories, getCatagories })(AddCat);
