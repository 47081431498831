import React, { Component } from "react";
import { Item, Button, Icon, Label, Image } from "semantic-ui-react";

let totalPrice = 0;
let date;
const getColor = (status) => {
  if (status == "pending") return "orange";
  else if (status == "fulfilling") return "yellow";
  else if (status == "delivering") return "green";
  else return "grey";
};
export class Items extends Component {
  componentDidMount() {
    let tots = 0;
    this.props.item.products.map((prod) => {
      tots = parseInt(prod.price) * parseInt(prod.quantity);
      totalPrice = tots + totalPrice;
    });
    date = this.props.item.products[0].orderDate.toDate();
  }
  render() {
    return (
      <>
        <Item>
          <Image
            rounded
            size="small"
            src={this.props.item.products[0].imageURL}
          />

          <Item.Content>
            <Item.Header
              as="a"
              onClick={() => this.props.setSelectedOrder(this.props.item)}
            >
              Order by {this.props.item.orderedBy.userInfo.name}
            </Item.Header>
            <Item.Meta>
              <span>
                Total Products: {this.props.item.products.length} Tota Amount:{" "}
                {totalPrice}
              </span>
            </Item.Meta>
            <Item.Description>
              <b>Order Date:</b>{" "}
              {new Date(date).toLocaleString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              <br />
              <b>Delivery Address:</b>
              {this.props.item.address.locName}
              <br />
              <b>Order ID:</b> {this.props.item.id}
              <br />
            </Item.Description>
            <Item.Extra>
              <Button
                primary
                floated="right"
                onClick={() => this.props.setSelectedOrder(this.props.item)}
              >
                More Details and Actions
                <Icon name="right chevron" />
              </Button>
              <Label color={getColor(this.props.item.status)}>
                {this.props.item.status}
              </Label>
            </Item.Extra>
          </Item.Content>
        </Item>
      </>
    );
  }
}

export default Items;
