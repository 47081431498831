import React, { Component } from "react";
import { connect } from "react-redux";
import { firestore } from "../Firebase/utils";
import { setProducts } from "../Redux/Products/products.actions";
import PropTypes from "prop-types";
import Carousel from "./carousel";
import Catagory from "./catagories";
import HighlightedProducts from './showProducts'
import RecentlySeen from './RecentlySeen'
import OneProduct from './oneProduct'
import Because from './BecauseYouViewed'

export class Home extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
  };

  cat = [];
  catIsDone = false

  setupCatagories = (products) => {
    if (!this.catIsDone) {
      let found
      products.map((item, iterator) => {
        this.cat.map((cat, i) => {
          if (cat.name === item.catagory) {
            this.cat[i] = { name: item.catagory, imageURL: item.imageURL, quantity: this.cat[i].quantity + 1 }
            console.log(i)
            found = true
          }
        })
        if (!found) {
          this.cat = [...this.cat, { name: item.catagory, imageURL: item.imageURL, quantity: 1 }]
        }
        found = false
      });
    }
    return this.cat
  };

  componentDidMount() {
    console.log(
      this.props.products[
        Math.floor(Math.random() * this.props.products.length)
      ]
    );
    

    if (this.props.products === null || this.props.products.length == 0) {
      firestore.collection("products").onSnapshot((snapshot) => {
        let productsArray = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        this.props.setProducts(productsArray);
        this.setupCatagories(productsArray);
      });
    }
  }
  getRandom = (len) => {
    console.log(Math.floor(Math.random() * len), len);
    return Math.floor(Math.random() * len)
  }
  render() {
    return (
      <>
        {console.log(
          this.props.products[
            Math.floor(Math.random() * this.props.products.length)
          ]
        )}
        <Carousel />
        <Catagory key="1" cat={this.cat} />
        <HighlightedProducts prod={this.props.products} />
        {this.props.products.length > 0 && (
          <OneProduct
            item={
              this.props.products[this.getRandom(this.props.products.length)]
            }
          />
        )}

        <RecentlySeen />
        <Because prod={this.props.products} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.products.products,
});
export default connect(mapStateToProps, { setProducts })(Home);
