import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  TextArea,
  Button,
  Icon,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { firestore, storage } from "../../../Firebase/utils";
import { v4 as uuid } from "uuid";

const Options = [
  { key: "1", text: "Catagory 1", value: "cat 1" },
  { key: "2", text: "Catagory 2", value: "cat 2" },
  { key: "3", text: "catagory 3", value: "cat 3" },
];

export class AddNew extends Component {
  state = {
    name: "",
    catagory: "",
    price: 0,
    quantity: 0,
    description: "",
    image: null,
    error: false,
    success: false,
    loading: false,
  };
  handelImageChange = (e) => {
    this.setState({ image: e.target.files[0] });
    console.log("image: ", e.target.files[0]);
  };
  handleSearchChange = (e, { value }) => {
    this.setState({ catagory: value });
    console.log(value);
  };

  handelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };
  handelSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true, error: false, success: false });

    const newUuid = uuid();

    const uploadTask = storage.ref(`images/${newUuid}`).put(this.state.image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
        this.setState({ success: false, loading: false, error: true });
        return;
      },
      () => {
        storage
          .ref("images")
          .child(newUuid)
          .getDownloadURL()
          .then((url) => {
            const timestamp = new Date();
            const data = {
              name: this.state.name,
              catagory: this.state.catagory,
              price: this.state.price,
              quantity: this.state.quantity,
              description: this.state.description,
              imageURL: url,
              imageUUId: newUuid,
              createdDate: timestamp,
            };

            firestore
              .collection("products")
              .doc()
              .set(data)
              .then(() => {
                this.setState({ success: true, loading: false, error: false });
                // this.props.updateComp();
              })
              .catch((err) => {
                console.log(err);
                this.setState({ success: false, loading: false, error: true });
              });
          });
      }
    );
  };

  render() {
    return (
      <>
        <Form onSubmit={this.handelSubmit} loading={this.state.loading}>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-name"
              control={Input}
              label="Product name"
              placeholder="Product name"
              name="name"
              onChange={this.handelChange}
            />
            <Form.Field>
              <label>Select Catagory</label>
              <Dropdown
                placeholder="Select Catagory"
                fluid
                search
                name="catagory"
                selection
                value={this.state.catagory}
                onChange={this.handleSearchChange}
                options={Options}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-price"
              control={Input}
              type="number"
              name="price"
              label="Price"
              placeholder="Price"
              onChange={this.handelChange}
            />
            <Form.Field
              id="form-input-control-quantity"
              control={Input}
              label="Quantity"
              name="quantity"
              type="number"
              placeholder="Quantity"
              onChange={this.handelChange}
            />
            <div className="field">
              <label>Add Product Image</label>
              <label className="ui left labeled icon button">
                <i className="image icon"></i>
                <input
                  type="file"
                  style={{ display: "none" }}
                  className="ui blue button"
                  accept="image/*"
                  onChange={this.handelImageChange}
                ></input>
                Product Image
              </label>
            </div>
          </Form.Group>
          <Form.Field
            id="form-textarea-control-opinion"
            control={TextArea}
            label="Description"
            placeholder="Description"
            name="description"
            onChange={this.handelChange}
          />
          <Button icon type="submit" labelPosition="right" color="black">
            Add product
            <Icon name="plus" />
          </Button>
        </Form>
        <Message
          error
          hidden={!this.state.error}
          content="Oopps... something went wrong. Please try again."
        />
        <Message
          positive
          icon="green check circle"
          hidden={!this.state.success}
          content={`${this.state.name} has been added to products list.`}
        />
      </>
    );
  }
}

export default AddNew;
