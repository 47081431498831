import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import Product from "./products";
import PropTypes from "prop-types";

export class someProducts extends Component {
  products = [];

  static propTypes = {
    prod: PropTypes.object.isRequired,
  };

  componentDidMount() {
    // this.products = this.props.prod.slice(0, 10);
    console.log(this.props.prod);
  }

  render() {
    return (
      <Segment style={{ borderRadius: "0", marginTop: "0", marginBottom: "0" }}>
        <Header as="h2" style={{ marginBottom: "0" }}>
          Highlighted Products
          <Header.Subheader>Enjoy some of our best products</Header.Subheader>
        </Header>
        <Segment
          basic
          style={{
            overflowY: "auto",
            whiteSpace: "nowrap",
            marginLeft: "-1rem",
            marginRight: "-1rem",
            marginTop: "0",
          }}
        >
          {this.props.prod.slice(0, 10).map((item) => {
            return <Product key={item.id} item={item} />;
          })}

          {/* <Product />
          <Product />
          <Product />
          <Product />
          <Product />
          <Product />
          <Product />
          <Product />
          <Product />
          <Product />
          <Product />
          <Product /> */}
        </Segment>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  prod: state.products.products,
});

export default connect(mapStateToProps)(someProducts);
