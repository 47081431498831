import React, { Component } from "react";
import { signInWithGoogle, auth } from "./../Firebase/utils";
import {
  Segment,
  Divider,
  Button,
  Icon,
  Header,
  Form,
  Input,
  Label,
  Grid,
  Message,
} from "semantic-ui-react";

export class Login extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    err: { err: false, code: "", msg: "" },
  };

  handelSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, err: { err: false, code: "", msg: {msg:"", header:""} } });
    try {
      await auth
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then((res) => {
          this.setState({ loading: false });
          console.log("Logged In");
        });
    } catch (err) {
      if (err.code == "auth/user-not-found") {
        this.setState({
          err: {
            err: true,
            code: err.code,
            msg: { msg: "We couldn't find a user with that email address.", header: "Unkown email address" },
          },
          loading: false,
        });
      }
      if (err.code == "auth/wrong-password") {
        this.setState({
          err: {
            err: true,
            code: err.code,
            msg: {
              msg: "The password you entered dose not belong to that email address or if you used a social login method to login please use that again.",
              header: "Incorrect password",
            },
          },
          loading: false,
        });
      }
      if (err.code == "auth/invalid-email") {
        this.setState({
          err: {
            err: true,
            code: err.code,
            msg: {
              msg:
                "Please enter a correct email address eg. example@example.com",
              header: "Invalid Email",
            },
          },
          loading: false,
        });
      }
      this.setState({loading: false})
      console.log(err);
    }
  };
  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <Segment raised textAlign="center">
        <Header as="h2">
          <Icon name="signup" />
          <Header.Content>Sign-in to your account</Header.Content>
        </Header>
        <Divider hidden />
        <Segment placeholder loading={this.state.loading}>
          <Grid columns={2} stackable textAlign="center">
            <Divider vertical>Or</Divider>

            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <Header as="h2">
                  <Header.Content>
                    Sign in with email and password
                    <Header.Subheader>
                      Use your email and password to login
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Form onSubmit={this.handelSubmit}>
                  <Form.Field
                    error={
                      this.state.err.code == "auth/invalid-email"
                        ? true
                        : undefined
                    }
                  >
                    <label style={{ float: "left" }}>Email</label>
                    <input
                      placeholder="Email"
                      onChange={this.handelChange}
                      name="email"
                      value={this.state.email}
                    />
                  </Form.Field>
                  <Form.Field
                    error={
                      this.state.err.code == "auth/wrong-password"
                        ? true
                        : undefined
                    }
                  >
                    <label style={{ float: "left" }}>Password</label>
                    <input
                      placeholder="Password"
                      type="password"
                      onChange={this.handelChange}
                      name="password"
                      value={this.state.password}
                    />
                  </Form.Field>

                  <Button
                    icon
                    type="submit"
                    labelPosition="right"
                    fluid
                    color="black"
                  >
                    Login
                    <Icon name="right arrow" />
                  </Button>
                  <Message negative compact hidden={!this.state.err.err}>
                    <Message.Header>{this.state.err.msg.header}</Message.Header>
                    {this.state.err.msg.msg}
                  </Message>
                  <br />
                  <a href="/recovery">Forgot your Password?</a>
                </Form>
              </Grid.Column>

              <Grid.Column>
                <Header as="h2">
                  <Header.Content>
                    Sign in with Social Media
                    <Header.Subheader>
                      Choose a Social Media platform to login with
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Button color="facebook" style={{ marginTop: ".5rem" }}>
                  <Icon name="facebook" /> Sign in with Facebook
                </Button>
                <Button
                  color="google plus"
                  style={{ marginTop: ".5rem" }}
                  onClick={signInWithGoogle}
                >
                  <Icon name="google" />
                  Sign in with Google
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment>
    );
  }
}

export default Login;
