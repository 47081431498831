import React, { Component } from "react";
import { connect } from "react-redux";
import BecauseSegment from './segments'

export class because extends Component {
  recent = [];
  allCat = [];
  componentWillMount() {
    this.recent = JSON.parse(localStorage.getItem("recent"));
  }
  
  renderSeg = () => {
    let count = 0;
    this.allCat = []
    this.recent.reverse().map((item) => {
      if (
        this.allCat.find((o) => {
          return o.catagory === item.catagory;
        }) === undefined &&
        count < 3
      ) {
        this.allCat.push(item);
        count = count + 1;
      }
    });
    console.log(this.allCat)

    if (this.allCat.length == 1) {
      return (
        <BecauseSegment
          inverted={false}
          prod={this.props.prod}
          item={this.allCat[0]}
        />
      );
    } else if (this.allCat.length == 2) {
      return (
        <>
          <BecauseSegment
            inverted={false}
            prod={this.props.prod}
            item={this.allCat[0]}
          />
          <BecauseSegment
            inverted={true}
            prod={this.props.prod}
            item={this.allCat[1]}
          />
        </>
      );
    } else{
      return (
        <>
          <BecauseSegment
            inverted={false}
            prod={this.props.prod}
            item={this.allCat[0]}
          />
          <BecauseSegment
            inverted={true}
            prod={this.props.prod}
            item={this.allCat[1]}
          />
          <BecauseSegment
            inverted={true}
            prod={this.props.prod}
            item={this.allCat[2]}
          />
        </>
      );
    }
  };
  render() {
    return <>{this.props.prod && this.renderSeg()}</>;
  }
}

const mapStateToProps = (state) => ({
  prod: state.products.products,
});

export default connect(mapStateToProps)(because);
